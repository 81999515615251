import React from 'react'
import { Link } from 'react-router-dom';

// const styleButton = "h-32 w-64 bg-cyan-500 rounded-xl flex flex-col items-center justify-center"
// https://flowbite.com/docs/components/buttons/
const styleButton = `
  drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]
  flex flex-col items-center justify-center
  text-white
  bg-gradient-to-r from-red-400 via-red-500 to-red-600
  hover:bg-gradient-to-br
  focus:ring-4 focus:outline-none focus:ring-red-300
  dark:focus:ring-red-800
  shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80
  font-medium
  rounded-lg
  text-sm
  px-5 py-2.5
  text-center
  me-2 mb-2
  h-32 w-64`

const HomePageMain = () => {
  return (
    <div className="p-3">
        <Link to="/serviceFEx" className="inline-block">
          <button className={styleButton}>
            <span className="pb-3 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
              Pojdi na pregled gasilnikov
            </span>
            <span>
              <svg
                className="w-12 h-12 fill-custom-table-header"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M64 480c0 17.67 14.33 32 31.1 32H256c17.67 0 31.1-14.33 31.1-32l-.0001-32H64L64 480zM503.4 5.56c-5.453-4.531-12.61-6.406-19.67-5.188l-175.1 32c-11.41 2.094-19.7 12.03-19.7 23.63L224 56L224 32c0-17.67-14.33-32-31.1-32H160C142.3 0 128 14.33 128 32l.0002 26.81C69.59 69.32 20.5 110.6 1.235 168.4C-2.952 181 3.845 194.6 16.41 198.8C18.94 199.6 21.48 200 24 200c10.05 0 19.42-6.344 22.77-16.41C59.45 145.5 90.47 117.8 128 108L128 139.2C90.27 157.2 64 195.4 64 240L64 416h223.1l.0001-176c0-44.6-26.27-82.79-63.1-100.8L224 104l63.1-.002c0 11.59 8.297 21.53 19.7 23.62l175.1 31.1c1.438 .25 2.875 .375 4.297 .375c5.578 0 11.03-1.938 15.37-5.562c5.469-4.562 8.625-11.31 8.625-18.44V23.1C511.1 16.87 508.8 10.12 503.4 5.56zM176 96C167.2 96 160 88.84 160 80S167.2 64 176 64s15.1 7.164 15.1 16S184.8 96 176 96z"
                />
              </svg>
            </span>
          </button>
        </Link>
    </div>
  )
}

export default HomePageMain
