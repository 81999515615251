import React, { useState, useRef, useEffect } from 'react'
import useAxios from './utils/useAxios'

const Test2 = () => {
  let [tableData, setTableData] = useState([])
  let api = useAxios()

  useEffect (() => {
    getData();
  }, [])
  let getData = async () => {
    try {
      let response = await api.get("/serviceFEx-api-v1/test2")
      if (response.status === 200) {
        setTableData(response.data)
      }
    } catch (error) {
      console.error("An error occurred: ", error.message)
    }
  }

  return (
    <div className='mx-2'>
      <h1>Test 2</h1>
      <p className='bg-fuchsia-600'>Paragraph</p>

      {/* {data.map((check, index) => (
        // <div key={index}>{check.fire_extinguisher.type}</div>
        <pre key={index}>{JSON.stringify(check, null, 2)}</pre>
      ))} */}
      {/* {console.log("tableData.data: ", tableData.data)} */}
      

        {console.log("tableData.data2: ", tableData.data)}
      {/* <pre>{JSON.stringify(tableData.headers, null, 2)}</pre>
      <pre>{JSON.stringify(tableData.data, null, 2)}</pre> */}
      <pre>{JSON.stringify(tableData, null, 2)}</pre>

      {console.log("tableData.data2: ", tableData.data)}

      <table>
        <thead>
          <tr>
            <th>Zap. št.</th>
            {tableData.headers && tableData.headers.map((header, index) => (
              <th key={index}>{header.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.rows && tableData.rows.map((row, index) => (
            <tr key={index}>
              <td>{index}</td>
              {tableData.headers.map((header, index) => {
                if ((new Date(row[header.key]) !== "Invalid Date") && !isNaN(new Date(row[header.key]))) {
                  return <td>{new Date(row[header.key]).toLocaleDateString()}</td>
                }
                return <td key={index}>{row[header.key]}</td>
              })}
            </tr>
          ))}
        </tbody>
      </table>


    </div>
  )
}



export default Test2

