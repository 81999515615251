import { createContext, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext()
export default AuthContext;

export const AuthProvider = ({children}) => {

  let [authTokens, setAuthTokens] = useState(() => localStorage.getItem("authTokens") ? JSON.parse(localStorage.getItem("authTokens")) : null)
  let [user, setUser] = useState(() => localStorage.getItem("authTokens") ? jwt_decode(localStorage.getItem("authTokens")) : null)
  let [loading, setLoading] = useState(true)
  const navigate =  useNavigate()

  let loginUser = async (e) => {
    e.preventDefault()
    // console.log('form submitted: ', e.target.username.value, e.target.password.value)
    let response =  await fetch (`${process.env.REACT_APP_API_ENDPOINT}/core-api-v1/token/`, {
      method:'POST',
      headers:{
        'Content-Type': 'application/json',
      },
      body:JSON.stringify({'username':e.target.username.value, 'password':e.target.password.value})
    })
    let data = await response.json()
    if (response.status === 200) {
      setAuthTokens(data)
      setUser(jwt_decode(data.access))
      localStorage.setItem("authTokens", JSON.stringify(data))
      navigate("../serviceFEx")
    } else {
      alert("Napačen uporabnik in/ali geslo")
    }
  }

  let logoutUser = () => {
    setUser(null)
    setAuthTokens(null)
    localStorage.removeItem("authTokens")
    navigate("/serviceFEx/login")
  }

  // let updateTokens = async () => {
  //   let response =  await fetch ('http://127.0.0.1:8000/core-api-v1/token/refresh/', {
  //     method:'POST',
  //     headers:{
  //       'Content-Type': 'application/json',
  //     },
  //     // body:JSON.stringify({"refresh": JSON.parse(localStorage.getItem("authTokens"))['refresh']})
  //     body: JSON.stringify({"refresh": authTokens.refresh})
  //   })
  //   let data = await response.json()
  //   if (response.status === 200) {
  //     setAuthTokens(data)
  //     console.log("user before: ", user)
  //     // setUser(jwt_decode(data.access))
  //     console.log("user after: ", user)
  //     localStorage.setItem("authTokens", JSON.stringify(data))
  //   } else {
  //     logoutUser()
  //     // alert("Update token failed! You are logged out.")
  //   }
  //   setLoading(false)
  // }  
  
  // useEffect(() => {
  //   if (loading && authTokens) {
  //     updateTokens()
  //   }
  //   let fourMinutes = 1000 * 60 * 4
  //   let interval = setInterval(() => {
  //     if (authTokens) {
  //       updateTokens();
  //     }
  //   }, fourMinutes)
  //   return () => clearInterval(interval)
  // }, [authTokens, loading])
  
  useEffect(() => {
    if (authTokens) {
      setUser(jwt_decode(authTokens.access))
    }
    setLoading(false)
  }, [authTokens, loading])
  
  let contextData = {
    loading:loading,
    authTokens:authTokens,
    user:user,
    setUser:setUser,
    setAuthTokens:setAuthTokens,
    loginUser:loginUser,
    logoutUser:logoutUser,
  }

  return (
    <AuthContext.Provider value={contextData}>
      {children}
    </AuthContext.Provider>
  )
}