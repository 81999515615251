import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

const contextClass = {
  success: "bg-green-600",
  error: "bg-custom-button-main",
  info: "bg-gray-600",
  warning: "bg-orange-400",
  default: "bg-indigo-600",
  dark: "bg-white-600 font-gray-300",
};

root.render(
  <React.StrictMode>
    <App />
    <ToastContainer
      toastClassName={({ type }) => contextClass[type || "default"] +
        " relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer"
      }
      bodyClassName={() => "text-sm flex flex-row font-white font-med p-3"}
      position="top-right"
      autoClose={3000}
    />
    
  </React.StrictMode>
  // <App />
);
