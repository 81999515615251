import axios from 'axios'   
import jwt_decode from 'jwt-decode'
import dayjs from 'dayjs'
import { useContext } from 'react'
import AuthContext from '../context/AuthContext'

// const baseURL = process.env.REACT_APP_API_ENDPOINT

const useAxios = () => {
  const {authTokens, setUser, setAuthTokens, logoutUser} = useContext(AuthContext)

  const axiosInstance = axios.create({
    // baseURL,
    baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
    // headers: {
    //   Authorization: `Bearer ${authTokens?.access}`
    // }
  });

  axiosInstance.interceptors.request.use(async req =>  {
    const userAccessToken = jwt_decode(authTokens.access)
    const isAccessTokenExpired = dayjs.unix(userAccessToken.exp).diff(dayjs(), 'second') < 1;
    if(isAccessTokenExpired){
      const userRefreshToken = jwt_decode(authTokens.refresh)
      const isRefreshTokenExpired = dayjs.unix(userRefreshToken.exp).diff(dayjs(), 'second') < 1;
      if (isRefreshTokenExpired){
        // console.log("Refreshing token expired!")
        logoutUser()
        return Promise.reject(new Error('Both tokens expired. User needs to log in again.'));
      } else {
        const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/core-api-v1/token/refresh/`, {
          refresh:authTokens.refresh
        })
        // console.log("response.status: ", response)
        if (response.status === 200) {
          localStorage.setItem("authTokens", JSON.stringify(response.data))
          setAuthTokens(response.data)
          setUser(jwt_decode(response.data.access))
          req.headers.Authorization = `Bearer ${response.data?.access}`
          // console.log(response.data)
          // console.log("tokens are equal: ", authTokens === response.data)
        }
      }
    } else {
      req.headers.Authorization = `Bearer ${authTokens?.access}`
    }

    return req
  })

  return axiosInstance
}

export default useAxios;