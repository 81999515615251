import React, {useEffect, useState} from 'react';
import TableOfChecks from './components/TableOfChecks';
import { Link, useParams } from 'react-router-dom';
import useAxios from './utils/useAxios'

const FireExtinguisher = () => {
  
  const sn = useParams().sn
  const production_year = useParams().production_year
  const [url, setUrl] = useState();
  let api = useAxios()
  let [fEx, setFex] = useState(null)

  useEffect (() => {
    // console.log("if: ", sn, production_year)
    if (sn && production_year) {
      getFex()
    }
    
  }, [])
  let getFex = async () => {
    try {
      let response = await api.get(`/serviceFEx-api-v1/review/FExMetadata?fire_extinguisher_sn=${sn}&production_year=${production_year}`)
      let data = response.data
      // console.log("fEx in getFex: ", data)
      setFex(data)
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  }
  // let getFex = async () => {
  //   let response = await fetch (`${process.env.REACT_APP_API_ENDPOINT}/serviceFEx-api-v1/review/FExMetadata?fire_extinguisher_sn=${sn}&production_year=${production_year}`)
  //   let data = await response.json()
  //   // console.log("fEx in getFex: ", data)
  //   setFex(data)
  // }

  let [checks, setChecks] = useState([])
  useEffect(() => {
    // getChecks();
    if (fEx !== null) {
      // getChecks();
      setUrl("/serviceFEx-api-v1/checks?fire_extinguisher_sn=" + fEx.sn + "&production_year=" + fEx.production_year)
    }
  }, [fEx]);
  // let getChecks = async () => {
  //   try {
  //     let response = await api.get(`/serviceFEx-api-v1/checks?fire_extinguisher_sn=${fEx.sn}&production_year=${fEx.production_year}`)
  //     // let response = await fetch(`http://127.0.0.1:8000/serviceFEx-api-v1/checks?fire_extinguisher_sn=${fEx.sn}&production_year=${fEx.production_year}`)
  //     // let response = await fetch("http://127.0.0.1:8000/serviceFEx-api-v1/checks/")
  //     // let data = await response.json()
  //     if (response.status === 200) {
  //       setChecks(response.data)
  //     }
  //   } catch (error) {
  //     console.error("An error occurred:", error.message);
  //   }
  // }

  return (
    <div>
      <h2>Ogled posameznega aparata</h2>
      {/* SN: {fEx.length > 0 && ( <p>SN: {fEx[0].sn}</p> )} */}
      
      {/* <p>
        {fEx ? (
          <>
            SN: {fEx.sn} <br />
            Proizvajalec: {fEx?.manufacturer?.name} <br />
            Tip: {fEx.type} <br />
            Leto proizvodnje: {fEx.production_year} <br />
            Lastnik: <Link to={`/serviceFEx/review/ownerFExs/${fEx?.owner?.name}`}>{fEx?.owner?.name}</Link> <br />
            Objekt: <Link to={`/serviceFEx/review/ownerFExs/${fEx?.owner?.name}/${fEx.object}`}>{fEx.object}</Link> <br />
            Lokacija: {fEx.location} <br />
            Opomba: {(fEx?.note?.length > 0 && (<span>{fEx?.note}</span> )) || <span>/</span>} <br />
          </>
          ) : (
            <></>
          )
        }       
      </p> */}
      <TableOfChecks url={url} />
    </div>
  )
}

export default FireExtinguisher