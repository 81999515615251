import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import useAxios from './utils/useAxios'
import TableOfChecks from './components/TableOfChecks';

const Review = () => {
  // URL: http://localhost:3000/serviceFEx/review
  const [owners, setOwners] = useState([])
  const [ownerObjects, setOwnerObjects] = useState([])
  const [servicemen, setServicemen] = useState([])
  const [manufacturers, setManufacturers] = useState([])
  const [sn, setSN] = useState([])
  const [snInput, setSNInput] = useState("")
  const [isLoading, setIsLoading] = useState(false)


  const [formOwner, setFormOwner] = useState("")
  // const [formObject, setFormObject] = useState("")
  // const [formServiceMan, setFormServiceMan] = useState("")
  // const [formManufacturer, setFormManufacturer] = useState("")
  // const [formMaintenanceDateFrom, setFormMaintenanceDateFrom] = useState([])
  // const [formMaintenanceDateTo, setFormMaintenanceDateTo] = useState([])
  // const [formValidityDateFrom, setFormValidityDateFrom] = useState([])
  // const [formValidityDateTo, setFormValidityDateTo] = useState([])
  const [url, setUrl] = useState("");

  // const selectInputRef = useRef();
  const selectOwnerRef = useRef(null);
  const selectObjectRef = useRef();
  const selectServicemanRef = useRef(null);
  const selectManufacturerRef = useRef(null);
  const selectMaintenanceDateGteRef = useRef(null);
  const selectMaintenanceDateLteRef = useRef(null);
  const selectValidityGteRef = useRef(null);
  const selectValidityLteRef = useRef(null);
  const selectSN = useRef(null);
  const selectYearRef = useRef(null);
  // const selectShowOnlyLastChecks = useRef(null);

  let api = useAxios()

  const styleSelect_old = "bg-custom-form-bg border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 block w-full outline-custom-form-outline "
  const styleSelectDateFromToContainer = "flex justify-between bg-custom-form-bg border border-gray-300 rounded p-1 "
  const styleSelectDateContainer = "flex flex-col "
  const styleDateInputField = "border rounded p-1 h-11 px-2 "
  const styleLabel = "text-sm font-medium text-gray-900 "
  const styleInputNumber = "bg-custom-form-bg border border-gray-300 text-gray-900 text-sm rounded lg p-2.5 w-full focus:outline-custom-form-outline"

  const styleSelect = {
    control: () => "!bg-custom-form-bg h-11 mb-3",
    menu: () => "bg-slate-200 ",
    dropdownIndicator: () => "!text-gray-600",
    // placeholder: () => "!text-red-500",
    singleValue: () => "!text-gray-900 text-sm",
    // option: () => "hover:bg-yellow-500",
  }

  // const customStyles = {
  //   control: (provided) => ({
  //     ...provided,
  //     backgroundColor: 'lightgray',
  //     border: '1px solid #ccc',
  //   }),
  //   option: (provided, state) => ({
  //     ...provided,
  //     backgroundColor: state.isSelected ? 'blue' : 'white',
  //     color: state.isSelected ? 'white' : 'black',
  //   }),
  // };

  // testing --------------------------
  // const [options, setOptions] = useState(['Apple', 'Banana', 'Cherry']);

  // owner ------------------------------
  useEffect (() => {
    getOwners();
  }, [])
  let getOwners = async () => {
    try {
      let response = await api.get('/serviceFEx-api-v1/owners/')
      if (response.status === 200) {
        setOwners(response.data)
      }
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  }
  const handleChangeOwner = (selectedOption) => {
    if (selectedOption) {
      setFormOwner(selectedOption.value)
    }
    else {
      setFormOwner(null)
    }
    // if changed owner, clean selected object
    if (selectObjectRef.current) {
      selectObjectRef.current.clearValue();
    }
  }

  // object ---------------------------
  // QUESTION If I comment this useEffect and call 'getObjects' from 'handleChangeOwner' the state 'ownerObjects' is one rendering behind
  useEffect (() => {
    getObjects();
  }, [formOwner])
  let getObjects = async () => {
    if (formOwner) {
      try {
        let response = await api.get(`/serviceFEx-api-v1/owners/${formOwner}/objects/`)
        if (response.status === 200) {
          setOwnerObjects(() => response.data)
          // console.log("Owner ", formOwner ," objects: ", ownerObjects)
        }
      } catch (error) {
        console.error("An error occurred:", error.message);
      }
    }
    else {
      setOwnerObjects([])
    }
  }
  // const handleChangeObject = (selectedOption) => {
  //   setFormObject(selectedOption.value)
  // }

  // servicemen -----------------------
  useEffect ( () => {
    getServicemen()
  }, []);
  let getServicemen = async () => {
    try {
      let response = await api.get(`/serviceFEx-api-v1/servicemen`)
      if (response.status === 200) {
        let data = response.data
        const justNames = data.map(item => (
          item.username
        ))
        setServicemen(justNames)
        // setOwnerObjects(() => response.data)
        // console.log("Owner ", formOwner ," objects: ", ownerObjects)
      }
    } catch (error) {
      console.error("An error occurred:", error.message);
    }

    // response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/serviceFEx-api-v1/servicemen`)
    // let data = await response.json()
    // const justNames = data.map(item => (
    //   item.username
    // ))
    // setServicemen(justNames)
    // console.log(justNames)
  }

  // manufacturers --------------------
  useEffect (() => {
    getManufacturers();
  }, [])
  let getManufacturers = async () => {
    try {
      let response = await api.get('/serviceFEx-api-v1/manufacturers/')
      if (response.status === 200) {
        // let data = response.data
        let justNames = response.data.map(item => (
          item.name
        ))
        setManufacturers(justNames)
      }
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  }

  // sn -------------------------------
  useEffect (() => {
    if (snInput.length > 0) {
      getSNDropdownData();
    }
  }, [snInput])
  let getSNDropdownData = async () => {
    try {
      let response = await api.get(`/serviceFEx-api-v1/fexs-sn-year?sn=${snInput}`)
      if (response.status === 200) {
        let data = response.data
        let sNs = data.map(fex => (fex.sn))
        // console.log(sNs)
        setSN(sNs)
      }
    } catch (error) {
      console.log("An error ocurred: ", error.message)
    }
  }
  // old stuff
  // const handleChangeSN = async (enteredValue) => {
  //   // if 'e' is not in focus, just hide the dropdown and return
  //   // setFormProductionYear("")
  //   console.log("handleChangeSN - in function")
  //   console.log("handleChangeSN, 'event': ", enteredValue)
  //   // setSNInput(enteredValue)

  //   // if (event.target.value) {
  //   //   console.log("if == true")
  //   //   const inputData = event.target.value.toLowerCase()
  //   //   try {
  //   //     let response = await api.get(`/serviceFEx-api-v1/fexs-sn-year?sn=${inputData}`)
  //   //     if (response.status === 200) {
  //   //       let data = response.data
  //   //       console.log(data)
  //   //       setSN(data)
  //   //     }
  //   //   } catch (error) {
  //   //     console.error("An error occurred:", error.message);
  //   //   }
  //   // }
  // }

  // search again ---------------------
  const searchAgain = () => {
    let searchAgainData = JSON.parse(localStorage.getItem("searchAgain"))
    if (searchAgainData !== null){
      Object.entries(searchAgainData).forEach(([key, value]) => {
        // newStateVariables[key] = useState(value)[0];

        // if (key == "owner") selectOwnerRef.current.setValue({ value: value, label: value });
        // if (key == "object") selectObjectRef.current.setValue({ value: value, label: value });
        // if (key == "serviceman") selectServicemanRef.current.setValue({ value: value, label: value });
        // if (key == "manufacturer") selectManufacturerRef.current.setValue({ value: value, label: value });
        // if (key == "maintenance_date__gte") selectMaintenanceDateGteRef.current.value = value;
        // if (key == "maintenance_date__lte") selectMaintenanceDateLteRef.current.value = value;
        // if (key == "validity__gte") selectValidityGteRef.current.value = value;
        // if (key == "validity__lte") selectValidityLteRef.current.value = value;
        // if (key == "year") selectYearRef.current.value = value;

        switch (key) {
          case "object": selectObjectRef.current.setValue({ value: value, label: value }); break;
          case "owner": selectOwnerRef.current.setValue({ value: value, label: value }); break;
          case "serviceman": selectServicemanRef.current.setValue({ value: value, label: value }); break;
          case "manufacturer": selectManufacturerRef.current.setValue({ value: value, label: value }); break;
          case "maintenance_date__gte": selectMaintenanceDateGteRef.current.value = value; break;
          case "maintenance_date__lte": selectMaintenanceDateLteRef.current.value = value; break;
          case "validity__gte": selectValidityGteRef.current.value = value; break;
          case "validity__lte": selectValidityLteRef.current.value = value; break;
          case "year": selectYearRef.current.value = value;  break;
          // case "showOnlyLastChecks": selectShowOnlyLastChecks.current.checked = value; break;
        }
      });
    }
    setUrl(searchAgainData.urlForTableOfChecks)
  }

  // handle submit --------------------
  const handleSubmit = (event) => {
    event.preventDefault();
    var urlForTableOfChecks = "/serviceFEx-api-v1/checks/?"
    var searchAgain = {}
    for (const field of event.target.elements) {
      if (field.value) {
        // dataToSend[field.name] = field.value;
        urlForTableOfChecks = urlForTableOfChecks + "&" + field.name + "=" + field.value
        searchAgain[field.name] = field.value
      }
    }

    // Handling checkbox
    // if (document.getElementById('showOnlyLastChecks').checked) {
    //   urlForTableOfChecks = urlForTableOfChecks + "&show_only_last_checks=true"
    //   searchAgain["showOnlyLastChecks"]="true";
    // }
    setUrl(urlForTableOfChecks)
    searchAgain["urlForTableOfChecks"] = urlForTableOfChecks
    localStorage.setItem("searchAgain", JSON.stringify(searchAgain))
  }

  // useEffect ( () => {
  //   // getChecks();
  //   setUrl("/serviceFEx-api-v1/checks")
  // })

  const isTableLoading = (newData) => {
    setIsLoading(newData)
  };

  return (
    <div>
      <div className='m-3 max-w-sm'>
        {localStorage.getItem("searchAgain") && 
          <button
            onClick={searchAgain} // search again
            className="custom-button-sm mt-1 mr-2"
            >
            Išči s prejšnjimi prametri
          </button>
        }
        <h2 className="font-medium text-xl mb-2">Izberi filtre za prikaz pregledov:</h2>
        <form onSubmit={handleSubmit}>
          <label className={styleLabel}  htmlFor="owner">Lastnik:</label>
          <Select
            options={owners.map(option => ({ label: option, value: option }))}
            onChange={handleChangeOwner}
            name="owner"
            inputId="owner"
            classNames={styleSelect}
            placeholder={<div>Izberi...</div>}
            isClearable
            ref={selectOwnerRef}
          />
          <label className={styleLabel} htmlFor="object">Objekt: </label><br />
          <Select
            options={ownerObjects
              .filter(option => option !== null && option !== "")
              .map(option => ({ label: option, value: option }))}
            name="object"
            inputId="object"
            classNames={styleSelect}
            placeholder={<div>Izberi...</div>}
            isClearable
            ref={selectObjectRef}
          />
          <label className={styleLabel} htmlFor="serviceman">Serviser: </label><br />
          <Select
            options={servicemen.map(option => ({ label: option, value: option }))}
            name="serviceman"
            inputId="serviceman"
            classNames={styleSelect}
            placeholder={<div>Izberi...</div>}
            isClearable
            ref={selectServicemanRef}
            />
          <label className={styleLabel} htmlFor="manufacturer">Proizvajalec RGA: </label><br />
          <Select
            options={manufacturers.map(option => ({ label: option, value: option }))}
            name="manufacturer"
            inputId="manufacturer"
            classNames={styleSelect}
            placeholder={<div>Izberi...</div>}
            isClearable
            ref={selectManufacturerRef}
          />
          <label className={styleLabel}>Datum vzdrževanja med:
            <div className={styleSelectDateFromToContainer + "mb-3"}>
              <div className={styleSelectDateContainer}>
                <label className={styleLabel} htmlFor="maintenance_date__gte">Od vključno:</label>
                <input type="date" name="maintenance_date__gte" id="maintenance_date__gte" className={styleDateInputField} ref={selectMaintenanceDateGteRef} />
              </div>
              <div className={styleSelectDateContainer}>
                <label className={styleLabel} htmlFor="maintenance_date__lte">Do vključno:</label>
                <input type="date" name="maintenance_date__lte" id="maintenance_date__lte" className={styleDateInputField} ref={selectMaintenanceDateLteRef} />
              </div>
            </div>
          </label>
          <label className={styleLabel}>Datum veljavnosti poteče med:
            <div className={styleSelectDateFromToContainer + "mb-3"}>
              <div className={styleSelectDateContainer}>
                <label className={styleLabel} htmlFor="validity__gte">Od vključno:</label>
                  <input type="date" name="validity__gte" id="validity__gte" className={styleDateInputField} ref={selectValidityGteRef} />
              </div>
              <div className={styleSelectDateContainer}>
                <label className={styleLabel} htmlFor="validity__lte">Do vključno:</label>
                  <input type="date" name="validity__lte" id="validity__lte" className={styleDateInputField} ref={selectValidityLteRef} />
              </div>
            </div>
          </label>
          <label className={styleLabel}  htmlFor="fire_extinguisher_sn">Serijska št.:</label>
          <Select
            options={
              sn.map(option => ({ label: option, value: option }))
            }
            onInputChange={(newValue) => {
              setSNInput(newValue)
            }}
            name="fire_extinguisher_sn"
            inputId="fire_extinguisher_sn"
            classNames={styleSelect}
            placeholder={<div>Izberi...</div>}
            isClearable
            ref={selectSN}
            // value={null}
          />
          <label className={styleLabel} htmlFor="year">Leto proizvodnje</label>
          <input
            className={styleInputNumber}
            type="number"
            id="year"
            name="year"
            ref={selectYearRef}
            min = {new Date().getFullYear()-80}
            max = {new Date().getFullYear()+1}
          />
          <br />
          {/* <div className="pt-3">
            <input
              className="
                mr-2
                w-4 h-4
                bg-white
                active:ring-custom-form-border
                active:ring-2
                rounded
                checked:bg-gradient-to-tl
                accent-custom-form-checked
                "
              id="showOnlyLastChecks"
              type="checkbox"
              ref={selectShowOnlyLastChecks}
            />
            <label
              htmlFor="showOnlyLastChecks"
              className="
                relative
                bottom-[0.125rem]
                rounded"
            >
              Prikaži le zadnji preged gasilnikov
            </label>
          </div> */}
          <button
            type="submit"
            className={`custom-button w-36 mt-5 ${isLoading ? 'cursor-not-allowed' : ''}`}
            disabled={isLoading ? "disabled" : ""}
          >
            Prikaži
          </button>
        </form>
      </div>
      <hr className="mt-3" />
      {url && <TableOfChecks url={url} isTableLoading={isTableLoading} />}
    </div>
  )
}

export default Review