import React, { useContext } from 'react'
import { Outlet, Navigate } from "react-router-dom";
import AuthContext from '../context/AuthContext';

const PrivateRoute = () => {
  // console.log("Private route works!")
  
  // const auth = true;  
  // return auth ? <Outlet /> : <Navigate to="/serviceFEx/login" />;
  
  let {user} = useContext(AuthContext)
  // console.log("user: ", user)
  return user ? <Outlet /> : <Navigate to="/serviceFEx/login" />;
}

export default PrivateRoute