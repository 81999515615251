// Testing how to style 'Select' component

import React from "react";
import Select from "react-select";
const SelectTailwind = () => {
    const options = [
        { value: "chocolate", label: "Chocolate" },
        { value: "strawberry", label: "Strawberry" },
        { value: "vanilla", label: "Vanilla" },
    ];
    return (
        <div className=" bg-white text-black  
                        flex flex-col  
                        justify-center 
                        items-center 
                        h-screen w-full">
            <h1 className=" flex flex-row 
                        text-green-500 
                        font-semibold 
                        text-2xl">
                GFG React-Select and Tailwind Forms Plugin
            </h1>
            <label htmlFor="wotailwind">Without Tailwind</label>
            <Select id="wotailwind" options={options} />
            <label htmlFor="wtailwind">With Tailwind</label>
            <Select
                id="wtailwind"
                unstyled
                options={options}
                classNames={{
                    input: () => "[&_input:focus]:ring-0",
                    control: () => "form-multiselect p-6 bg-red-500 ",
                    menu: () => "bg-slate-200 ",
                }}
            />
        </div>
    );
};
export default SelectTailwind;