import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom';
import useAxios from '../utils/useAxios'
import AuthContext from '../context/AuthContext';

const TableOfChecks = ({url, isTableLoading}) => {
  // console.log("Inside 'TableOfChecks'.")
  // console.log("Checks inside 'TableOfChecks' module: ", checks)
  // console.log("When 'TableOfChecks' component is called, the length of checks is: ", checks.length)
  const [showOnlyLastChecks, setShowOnlyLastChecks] = useState(false);
  const [showMoreLessIndividually, setShowMoreLessIndividually] = useState([]);
  const [filteredChecks, setFilteredChecks] = useState();
  let {user, logoutUser} = useContext(AuthContext)
  let [checks, setChecks] = useState([])
  let [internalUrl, setInternalUrl] = useState();
  let [loading, setLoading] = useState(true)
  // let [pagination, setPagination] = useState("")
  // console.log("In TableOfChecks, the variable 'url' is ", internalUrl)

  let api = useAxios()

  // style variables
  const styleMobileOneRowInCard = "border-b py-[2px] grid grid-cols-2 "

  // // Assume some data is retrieved or processed, and you want to send it to the parent
  // const newData = "New data from TableOfChecks";

  // // Call the callback function provided by the parent
  // isTableLoading(newData);

  useEffect(() => {
    if (url)
      setInternalUrl(url);
  }, [url]);

  useEffect (() => {
    if (internalUrl) {
      getChecks()
    } 
  }, [internalUrl])

  useEffect (() => {
    isTableLoading && isTableLoading(loading)
  }, [loading])

  let getChecks = async () => {
    // console.log("(getChecks): internalUrl: ", internalUrl)
    setLoading(() => true)
    try {
      let response = await api.get(internalUrl)
      if (response.status === 200) {
        // let data = await response.json()
        setChecks(response.data)
        // setChecks(response.data.payload)
        // setPagination(response.data.pagination)
      }
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
    setLoading(() => false)
    // console.log(data)
  }

  useEffect (() => {
    // if (showOnlyLastChecks) {
    //   const onlyLastChecksObjects = {};
    //   // QUESTION if checkbox 'show only last checks' is checked and instead `checks.forEach` is `filteredChecks.forEach` when selecting another object, the table is still the same as before
    //   checks.forEach((check, index) => {
    //     const currentFireExtinguisherId = check.fire_extinguisher.id;
    //     const currentValidity = new Date(check.validity)
    //     const dateFromLastChecksObjects = new Date (onlyLastChecksObjects[currentFireExtinguisherId]?.validity)
    //     if (!onlyLastChecksObjects[currentFireExtinguisherId] || currentValidity > dateFromLastChecksObjects) {
    //       // console.log("Adding fex ", check.fire_extinguisher.sn, " to objects")
    //       onlyLastChecksObjects[currentFireExtinguisherId] = { ...check }; // QUESTION what are doing those three dots here: using the spread (...) syntax to create a shallow copy of the 'check' and assigns it to the 'onlyLastChecksObjects' object with the key 'fireExtinguisherId'.
    //     }
    //   })
    //   // console.log(onlyLastChecksObjects)
    //   const onlyLastChecksArray = Object.values(onlyLastChecksObjects);
    //   setFilteredChecks(() => onlyLastChecksArray)
    //   // setFilteredChecks(onlyLastChecksArray)
    // } else {
    //   setFilteredChecks(() => checks)
    //   // setFilteredChecks(checks)
    // }
    // // console.log(filteredChecks)

    setFilteredChecks(() => checks)
  }, [/*showOnlyLastChecks,*/ checks])

  const toggleShowMoreLessIndividually = (index) => {
    const newShowMoreLessIndividually = [...showMoreLessIndividually];
    newShowMoreLessIndividually[index] = !newShowMoreLessIndividually[index];
    setShowMoreLessIndividually(newShowMoreLessIndividually);
    // console.log("toggle show more / less: ", showMoreLessIndividually)
  }

  const [sortOrder, setSortOrder] = useState("ASC");
  const [sortingColumn, setSortingColumn] = useState();
  // const Sorting = (col, event) => {
  const Sorting = (col) => {
    // console.log("event: ", event)
    setSortingColumn(col)
    switch(col) {
      case "manufacturer":
        if (sortOrder === "ASC") {
          const sorted = [...filteredChecks].sort((a, b) =>
          a.fire_extinguisher.manufacturer.name.toLowerCase() > b.fire_extinguisher.manufacturer.name.toLowerCase() ? 1 : -1
          );
          setFilteredChecks(sorted);
          setSortOrder("DSC");
          // event.target.textContent = event.target.textContent + "△"
          // event.target.querySelector('span').textContent = "△"
        }
        else if (sortOrder === "DSC") {
          const sorted = [...filteredChecks].sort((a, b) =>
            a.fire_extinguisher.manufacturer.name.toLowerCase() < b.fire_extinguisher.manufacturer.name.toLowerCase() ? 1 : -1
          );
          setFilteredChecks(sorted)
          setSortOrder("ASC");
        }
        break;
      case "fex_type":
        if (sortOrder === "ASC") {
          const sorted = [...filteredChecks].sort((a, b) =>
            a.fire_extinguisher.type.toLowerCase() > b.fire_extinguisher.type.toLowerCase() ? 1 : -1
          );
          setFilteredChecks(sorted);
          setSortOrder("DSC");
        }
        else if (sortOrder === "DSC") {
          const sorted = [...filteredChecks].sort((a, b) =>
            a.fire_extinguisher.type.toLowerCase() < b.fire_extinguisher.type.toLowerCase() ? 1 : -1
          );
          setFilteredChecks(sorted)
          setSortOrder("ASC");
        }
        break;
      case "fex_sn":
        if (sortOrder === "ASC") {
          const sorted = [...filteredChecks].sort((a, b) =>
            a.fire_extinguisher.sn.toLowerCase() > b.fire_extinguisher.sn.toLowerCase() ? 1 : -1
          );
          setFilteredChecks(sorted);
          setSortOrder("DSC");
        }
        else if (sortOrder === "DSC") {
          const sorted = [...filteredChecks].sort((a, b) =>
            a.fire_extinguisher.sn.toLowerCase() < b.fire_extinguisher.sn.toLowerCase() ? 1 : -1
          );
          setFilteredChecks(sorted)
          setSortOrder("ASC");
        }
        break;
      case "fex_production_year":
        if (sortOrder === "ASC") {
          const sorted = [...filteredChecks].sort((a, b) =>
            a.fire_extinguisher.production_year > b.fire_extinguisher.production_year ? 1 : -1
          );
          setFilteredChecks(sorted);
          setSortOrder("DSC");
        }
        else if (sortOrder === "DSC") {
          const sorted = [...filteredChecks].sort((a, b) =>
            a.fire_extinguisher.production_year < b.fire_extinguisher.production_year ? 1 : -1
          );
          setFilteredChecks(sorted)
          setSortOrder("ASC");
        }
        break;
      case "maintenance_date":
        if (sortOrder === "ASC") {
          const sorted = [...filteredChecks].sort((a, b) =>
            a.maintenance_date > b.maintenance_date ? 1 : -1
          );
          setFilteredChecks(sorted);
          setSortOrder("DSC");
        }
        else if (sortOrder === "DSC") {
          const sorted = [...filteredChecks].sort((a, b) =>
            a.maintenance_date < b.maintenance_date ? 1 : -1
          );
          setFilteredChecks(sorted)
          setSortOrder("ASC");
        }
        break;
      case "validity":
        if (sortOrder === "ASC") {
          const sorted = [...filteredChecks].sort((a, b) =>
            a.validity > b.validity ? 1 : -1
          );
          setFilteredChecks(sorted);
          setSortOrder("DSC");
        }
        else if (sortOrder === "DSC") {
          const sorted = [...filteredChecks].sort((a, b) =>
            a.validity < b.validity ? 1 : -1
          );
          setFilteredChecks(sorted)
          setSortOrder("ASC");
        }
        break;
      case "serviceman":
        if (sortOrder === "ASC") {
          const sorted = [...filteredChecks].sort((a, b) =>
            a.serviceman.username.toLowerCase() > b.serviceman.username.toLowerCase() ? 1 : -1
          );
          setFilteredChecks(sorted);
          setSortOrder("DSC");
        }
        else if (sortOrder === "DSC") {
          const sorted = [...filteredChecks].sort((a, b) =>
            a.serviceman.username.toLowerCase() < b.serviceman.username.toLowerCase() ? 1 : -1
          );
          setFilteredChecks(sorted)
          setSortOrder("ASC");
        }
        break;
      case "owner":
        if (sortOrder === "ASC") {
          const sorted = [...filteredChecks].sort((a, b) => {
            // console.log("inside comparison ASC")
            // console.log(a.fire_extinguisher.owner)
            // console.log("a: ", a.fire_extinguisher.owner ? true : false)
            // console.log("b: ", a.fire_extinguisher.owner ? true : false)
            let aa = a.fire_extinguisher.owner ? a.fire_extinguisher.owner.name.toLowerCase() : "";
            let bb = b.fire_extinguisher.owner ? b.fire_extinguisher.owner.name.toLowerCase() : "";
            return aa > bb ? 1 : -1
        });
          setFilteredChecks(sorted);
          setSortOrder("DSC");
        }
        else if (sortOrder === "DSC") {
          const sorted = [...filteredChecks].sort((a, b) => {
            // console.log("inside comparison DSC")
            let aa = a.fire_extinguisher.owner ? a.fire_extinguisher.owner.name.toLowerCase() : "";
            let bb = b.fire_extinguisher.owner ? b.fire_extinguisher.owner.name.toLowerCase() : "";
            return aa < bb ? 1 : -1
          });
          setFilteredChecks(sorted)
          setSortOrder("ASC");
        }
        break;
      case "object":
        if (sortOrder === "ASC") {
          const sorted = [...filteredChecks].sort((a, b) =>
            a.fire_extinguisher.object.toLowerCase() > b.fire_extinguisher.object.toLowerCase() ? 1 : -1
          );
          setFilteredChecks(sorted);
          setSortOrder("DSC");
        }
        else if (sortOrder === "DSC") {
          const sorted = [...filteredChecks].sort((a, b) =>
            a.fire_extinguisher.object.toLowerCase() < b.fire_extinguisher.object.toLowerCase() ? 1 : -1
          );
          setFilteredChecks(sorted)
          setSortOrder("ASC");
        }
        break;
      default:
        return 0
    }
  }

  const deleteCheck = async (checkID) => {
    const userConfirmed = window.confirm('Si prepričan da želiš izbrisati pregled?');
    if (userConfirmed) {
      try {
        let response = await api.delete(`${process.env.REACT_APP_API_ENDPOINT}/serviceFEx-api-v1/checks/delete`, {
          data: {
            "checkID": checkID
          },
        });
        if (response.status === 200){
          setFilteredChecks( filteredChecks => {
            return filteredChecks.filter(check => check.id !== checkID)
          })
        }
      }
      catch (error) {
        // Handle any other errors that may occur during the fetch request
        // console.error('Data:', error.response.data);
        // console.error('Status:', error.response.status);
        alert(`Prišlo je do napake. Kontaktiraj administratorja.\nJavi napako:\n${error}\n${error.response.data.message}`);
      }
    }
  }

  return (
    <div data-info="table-of-checks-container">
      {/* <pre className='text-red-500'>{JSON.stringify(filteredChecks[0], null, 2)}</pre> */}
      {/* <pre className='text-red-500'>{JSON.stringify(filteredChecks, null, 2)}</pre> */}

      <div>(Prikazanih: {filteredChecks?.length})</div>
      <label>
        <input
          className="mx-2"
          type="checkbox"
          checked={showOnlyLastChecks}
          id="show-only-last-checks"
          onChange={() => {
            // console.log("(onChange):  internalUrl: ", internalUrl)
            // let tmpUrl = "/serviceFEx-api-v1/checks/?owner=Avtoprevozništvo Rafko&show_only_last_checks=true"
            // const params = new URLSearchParams(tmpUrl);
            // const params = new URLSearchParams();
            let params;
            if (internalUrl.includes('?')) {
              params = new URLSearchParams(internalUrl.substring(internalUrl.indexOf('?') + 1));
            } else {
              params = new URLSearchParams(internalUrl);
            }
            // console.log("params: ", params.toString())
            if (showOnlyLastChecks) { // unchecked
              params.delete('show_previous_checks');
              setInternalUrl("/serviceFEx-api-v1/checks/?" + params.toString());
              // console.log("(unchecked): internalUrl-updated: ", "/serviceFEx-api-v1/checks/?" + params.toString())
            } else { // checked
              params.set('show_previous_checks', 'true');
              setInternalUrl("/serviceFEx-api-v1/checks/?" + params.toString());
              // console.log("(checked):   internalUrl-updated: ", "/serviceFEx-api-v1/checks/?" + params.toString())
            }
            setShowOnlyLastChecks(!showOnlyLastChecks)
          }}
        />
        Prikaži tudi prejšnje preglede gasilnikov
      </label>

      {/* mobile ******************************************************* */}
      {!loading && filteredChecks?.map((check, index) => (
        <div
        key={index}
        className="
            sm:hidden w-full p-2 my-2
            bg-red-900
            [&>*:nth-last-child(2)]:border-b-0
          "
        >
          {/* <span>Zap. št.</span> */}

          <div className={styleMobileOneRowInCard}>
            <span className="font-bold">Serijska št.</span>
            <Link
              className="custom-table-link !font-bold"
              to={`/serviceFEx/review/FEx/${check.fire_extinguisher.sn}/${check.fire_extinguisher.production_year}`}>
                {check.fire_extinguisher.sn}
            </Link>
          </div>
          
          <div className={styleMobileOneRowInCard}>
            <span>Veljavnost do</span>
            <span>
              <span>{check.validity}</span>
              {new Date(check.validity) < new Date() && (
                <>
                  <span className="inline-flex items-center align-middle relative -top-[0.125rem] mr-2 ml-1">
                    {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg> */}
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                    </svg>
                  </span>
                </>
                )}
            </span>
          </div>

          <div className={styleMobileOneRowInCard}>
            <span>Lastnik</span>
            {check.fire_extinguisher.owner ?
              <Link
              className="custom-table-link"
              to={`/serviceFEx/review/ownerFExs/${check.fire_extinguisher?.owner?.name}`}>
                  {check.fire_extinguisher.owner?.name}
              </Link>
            :
            <span>/</span>
            }
          </div>

          <div className={styleMobileOneRowInCard}>
            <span>Objekt</span>
            {check.fire_extinguisher.object ?
              <Link
                className="custom-table-link"
                to={`/serviceFEx/review/ownerFExs/${check.fire_extinguisher.owner?.name}/${check.fire_extinguisher.object}`}>
                  {check.fire_extinguisher.object}
              </Link>
            :
            <span>/</span>
            }
          </div>

          <div className={styleMobileOneRowInCard}>
            <span>Lokacija</span>
            <span>{check.fire_extinguisher.location || "/"}</span>
          </div>

          {showMoreLessIndividually[index] && (
            <>       
              <div className={styleMobileOneRowInCard}>
                <span>Poraba materiala</span>
                { check.used_material.length > 0 ?
                  <span>{check.used_material.map((used_mat, index) => (
                    <div key={index} >{used_mat.material.name} [{used_mat.material.unit}]: {used_mat.quantity}</div>
                    ))}
                  </span>
                  :
                  <span>/</span>
                }
              </div>

              <div className={styleMobileOneRowInCard}>
                <span>Leto izdelave</span>
                <Link
                  className="custom-table-link"
                  to={`/serviceFEx/review/FEx/production-year/${check.fire_extinguisher.production_year}`}>
                    {check.fire_extinguisher.production_year}
                </Link>
              </div>

              <div className={styleMobileOneRowInCard}>
                <span>Tip aparata</span>
                <span>{check.fire_extinguisher.type}</span>
              </div>

              <div className={styleMobileOneRowInCard}>
                <span>Proizvajalec</span>
                <Link
                  className="custom-table-link"
                  to={`/serviceFEx/review/FEx/manufacturer/${check.fire_extinguisher.manufacturer.name}`}>
                    {check.fire_extinguisher.manufacturer.name}
                </Link>
              </div>

              <div className={styleMobileOneRowInCard}>
                <span>Opomba gasilnika</span>
                <span>{check.fire_extinguisher.note}</span>
              </div>

              <div className={styleMobileOneRowInCard}>
                <span>Vrsta vzdrževanja</span>
                <span>
                  {check.maintenance_type.map((maintenance, index ) => (
                    <div key={index} className="p-1.5 text-xs font-medium uppercase bg-custom-table-badge rounded-lg tracking-wider bg-opacity-70 shadow-sm my-1" >{maintenance.type}</div>
                  ))}
                </span>
              </div>

              <div className={styleMobileOneRowInCard}>
                <span>Datum vzdrževanja</span>
                <Link
                  className="custom-table-link"
                  to={`/serviceFEx/review/date/${check.maintenance_date}`}>
                    {check.maintenance_date}
                </Link>
              </div>

              <div className={styleMobileOneRowInCard}>
                <span>Serviser</span>
                <Link
                  className="custom-table-link"
                  to={`/serviceFEx/review/serviceman/${check.serviceman.username}`}>
                    {check.serviceman.username}
                </Link>
              </div>

              <div className={styleMobileOneRowInCard}>
                <span>Opomba pregleda</span>
                <span>{check.note || "/"}</span>
              </div>

              <div className={styleMobileOneRowInCard}>
                <span>
                  Spreminjanje
                  <br />
                  (2 dni)
                </span>
                  {/* TODO mobile - power user can always edit checks  */}
                {(user.username === check.serviceman.username && (new Date() - new Date(check.maintenance_date) <= 2 *24 * 60 * 60 * 1000)) &&
                  <div className='flex flex-row space-x-2 justify-center [&>*]:cursor-pointer'>
                  <Link to={`/serviceFEx/check/${check.fire_extinguisher.sn}/${check.fire_extinguisher.production_year}/${check.id}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                    </svg>
                  </Link>
                  {/* <Link to={`/serviceFEx/review/FEx/${check.fire_extinguisher.sn}/${check.fire_extinguisher.production_year}/${check.id}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                    </svg>
                  </Link> */}
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6"
                      onClick={() => deleteCheck(check.id)}
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                    </svg>
                  </span>
                </div>
                }
              </div>
            </>
          )}
          <button
            onClick={() => toggleShowMoreLessIndividually(index)}
            className="custom-button-sm mt-1 mr-2"
            >
            {showMoreLessIndividually[index] ? 'Skrči' : 'Pokaži več'}
          </button>
        </div>
      ))}

      {/* desktop ******************************************************* */}
      <table className="hidden sm:block text-center w-full border-collapse">
        <thead className="bg-custom-table-header text-me border-2 border-custom-table-border text-white sticky top-1">
          <tr className='
            [&>*]:border-custom-table-border
            [&>*]:border-1
            [&>*]:p-2
            [&>th>span]:cursor-pointer'
          >
            <th>Zap. št.</th>
            {/* <th onClick={event => Sorting("manufacturer", event)}>Proizvajalec <span>△▽</span></th> */}
            <th onClick={() => Sorting("manufacturer")}>
              Proizvajalec
                {sortingColumn === "manufacturer" && sortOrder === "ASC" && <span> ▽</span> }
                {sortingColumn === "manufacturer" && sortOrder === "DSC" && <span> △</span> }
                {sortingColumn !== "manufacturer" && <span> △▽</span> }
            </th>
            <th onClick={() => Sorting("fex_type")}>
              Tip aparata
                {sortingColumn === "fex_type" && sortOrder === "ASC" && <span> ▽</span> }
                {sortingColumn === "fex_type" && sortOrder === "DSC" && <span> △</span> }
                {sortingColumn !== "fex_type" && <span> △▽</span> }
            </th>
            <th onClick={() => Sorting("fex_sn")}>
              Serijska št.
                {sortingColumn === "fex_sn" && sortOrder === "ASC" && <span> ▽</span> }
                {sortingColumn === "fex_sn" && sortOrder === "DSC" && <span> △</span> }
                {sortingColumn !== "fex_sn" && <span> △▽</span> }
            </th>
            <th onClick={() => Sorting("fex_production_year")}>
              Leto izdelave
                {sortingColumn === "fex_production_year" && sortOrder === "ASC" && <span> ▽</span> }
                {sortingColumn === "fex_production_year" && sortOrder === "DSC" && <span> △</span> }
                {sortingColumn !== "fex_production_year" && <span> △▽</span> }
            </th>
            <th>Opomba gasilnika</th>
            <th>Vrsta vzdrževanja</th>
            <th onClick={() => Sorting("maintenance_date")}>
              Datum vzdrževanja
                {sortingColumn === "maintenance_date" && sortOrder === "ASC" && <span> ▽</span> }
                {sortingColumn === "maintenance_date" && sortOrder === "DSC" && <span> △</span> }
                {sortingColumn !== "maintenance_date" && <span> △▽</span> }
              </th>
            <th onClick={() => Sorting("validity")}>
              Veljavnost do
                {sortingColumn === "validity" && sortOrder === "ASC" && <span> ▽</span> }
                {sortingColumn === "validity" && sortOrder === "DSC" && <span> △</span> }
                {sortingColumn !== "validity" && <span> △▽</span> }
            </th>
            <th onClick={() => Sorting("serviceman")}>
              Serviser
                {sortingColumn === "serviceman" && sortOrder === "ASC" && <span> ▽</span> }
                {sortingColumn === "serviceman" && sortOrder === "DSC" && <span> △</span> }
                {sortingColumn !== "serviceman" && <span> △▽</span> }
            </th>
            <th onClick={() => Sorting("owner")}>
              Lastnik
                {sortingColumn === "owner" && sortOrder === "ASC" && <span> ▽</span> }
                {sortingColumn === "owner" && sortOrder === "DSC" && <span> △</span> }
                {sortingColumn !== "owner" && <span> △▽</span> }
              </th>
            <th onClick={() => Sorting("object")}>
              Objekt
                {sortingColumn === "object" && sortOrder === "ASC" && <span> ▽</span> }
                {sortingColumn === "object" && sortOrder === "DSC" && <span> △</span> }
                {sortingColumn !== "object" && <span> △▽</span> }
            </th>
            <th>Lokacija</th>
            <th>Poraba materiala</th>
            <th>Opomba pregleda</th>
            <th>
              Spreminjanje
              <br />
              (2 dni)
            </th>
          </tr>
        </thead>
        {!loading &&
          <tbody className='border-2 border-custom-table-border'>
            {filteredChecks?.map((check, index) => (
              // <LineInTable key={index} check={check} />
              <tr className="
                odd:bg-custom-table-child-odd-bg odd:hover:bg-gray-200
                even:bg-custom-table-child-even-bg even:hover:bg-gray-200
                [&>*]:border-custom-table-border [&>*]:border-1 [&>*]:p-2
                "
                key={index}
              >
                <td>{index+1}</td>
                {/* <td><Link to="">{check.fire_extinguisher.manufacturer.name}</Link></td> */}
                <td>
                  <Link
                    className="custom-table-link"
                    to={`/serviceFEx/review/FEx/manufacturer/${check.fire_extinguisher.manufacturer.name}`}>
                      {check.fire_extinguisher.manufacturer.name}
                  </Link>
                </td>
                <td>{check.fire_extinguisher.type}</td>
                <td>
                  <Link
                    className="custom-table-link"
                    to={`/serviceFEx/review/FEx/${check.fire_extinguisher.sn}/${check.fire_extinguisher.production_year}`}>
                      {check.fire_extinguisher.sn}
                  </Link>
                </td>
                <td>
                  <Link
                    className="custom-table-link"
                    to={`/serviceFEx/review/FEx/production-year/${check.fire_extinguisher.production_year}`}>
                      {check.fire_extinguisher.production_year}
                  </Link>
                </td>
                {/* <td>{check.fire_extinguisher.production_year}</td> TODO Make 'Production year' link */}
                {/* <td>{check.maintenance_type}</td> */}
                <td>{check.fire_extinguisher.note}</td>
                <td>
                  {check.maintenance_type.map((maintenance, index ) => (
                    <div key={index} className="p-1.5 text-xs font-medium uppercase bg-custom-table-badge rounded-lg tracking-wider bg-opacity-70 shadow-sm my-1" >{maintenance.type}</div>
                  ))}
                </td>
                <td>
                  <Link
                    className="custom-table-link"
                    to={`/serviceFEx/review/date/${check.maintenance_date}`}>
                      {(new Date(check.maintenance_date)).toLocaleDateString()}
                  </Link>
                </td>
                <td className=''>
                  {(new Date(check.validity)).toLocaleDateString()}
                  {new Date(check.validity) < new Date() && (
                  <>
                    <br />
                    <span className="inline-flex items-center align-middle -top-[0.125rem] mr-2 ml-1">
                      {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg> */}
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                      </svg>
                    </span>
                  </>
                  )}
                </td>
                <td>
                  <Link
                    className="custom-table-link"
                    to={`/serviceFEx/review/serviceman/${check.serviceman.username}`}>
                      {check.serviceman.username}
                  </Link>
                </td>
                <td>
                  <Link
                    className="custom-table-link"
                    to={`/serviceFEx/review/ownerFExs/${check.fire_extinguisher?.owner?.name}`}>
                      {check.fire_extinguisher.owner?.name}
                  </Link>
                </td>
                <td>
                  <Link
                    className="custom-table-link"
                    to={`/serviceFEx/review/ownerFExs/${check.fire_extinguisher.owner?.name}/${check.fire_extinguisher.object}`}>
                      {check.fire_extinguisher.object}
                  </Link>
                </td>
                <td>{check.fire_extinguisher.location}</td>
                <td>
                  {check.used_material.map((used_mat, index) => (
                    <div key={index} >{used_mat.material.name} [{used_mat.material.unit}]: {used_mat.quantity}</div>
                  ))}
                </td>
                <td>
                  {check.note}
                  {/* {check.serviceman && <span>edit</span>}
                  {check.id} */}
                  </td>
                  <td className=''>
                  {/* TODO PC - power user can always edit checks  */}
                  {(user.username === check.serviceman.username && (new Date() - new Date(check.maintenance_date) <= 2 *24 * 60 * 60 * 1000)) &&
                    <div className='flex flex-row space-x-2 justify-center [&>*]:cursor-pointer'>
                      <Link to={`/serviceFEx/check/${check.fire_extinguisher.sn}/${check.fire_extinguisher.production_year}/${check.id}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                        </svg>
                      </Link>
                      {/* <Link to={`/serviceFEx/review/FEx/${check.fire_extinguisher.sn}/${check.fire_extinguisher.production_year}/${check.id}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                        </svg>
                      </Link> */}
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6"
                          onClick={() => deleteCheck(check.id)}
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                        </svg>
                      </span>
                    </div>
                  }
                  </td>
              </tr>
            ))
            }
          </tbody>
        }
      </table>
      {loading && <div className="pt-2 text-left">Pridobivam preglede ...</div>}
      {!internalUrl && <div>Iskalni parametri niso podani ...</div>}
    </div>
  )
}

export default TableOfChecks