import React, { useContext } from 'react'
import AuthContext from './context/AuthContext'
import { Navigate } from 'react-router-dom'

const LoginPage = () => {
  let {user, loginUser} = useContext(AuthContext)
  
  return user ? <Navigate to="/serviceFEx" /> : (
    <div>
      <h1 className="text-6xl sticky">  
        Servis RGA
        <span className="inline-flex items-center align-middle relative -top-2 ml-4">
          <svg
            className="w-12 h-12 fill-custom-table-header"
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M64 480c0 17.67 14.33 32 31.1 32H256c17.67 0 31.1-14.33 31.1-32l-.0001-32H64L64 480zM503.4 5.56c-5.453-4.531-12.61-6.406-19.67-5.188l-175.1 32c-11.41 2.094-19.7 12.03-19.7 23.63L224 56L224 32c0-17.67-14.33-32-31.1-32H160C142.3 0 128 14.33 128 32l.0002 26.81C69.59 69.32 20.5 110.6 1.235 168.4C-2.952 181 3.845 194.6 16.41 198.8C18.94 199.6 21.48 200 24 200c10.05 0 19.42-6.344 22.77-16.41C59.45 145.5 90.47 117.8 128 108L128 139.2C90.27 157.2 64 195.4 64 240L64 416h223.1l.0001-176c0-44.6-26.27-82.79-63.1-100.8L224 104l63.1-.002c0 11.59 8.297 21.53 19.7 23.62l175.1 31.1c1.438 .25 2.875 .375 4.297 .375c5.578 0 11.03-1.938 15.37-5.562c5.469-4.562 8.625-11.31 8.625-18.44V23.1C511.1 16.87 508.8 10.12 503.4 5.56zM176 96C167.2 96 160 88.84 160 80S167.2 64 176 64s15.1 7.164 15.1 16S184.8 96 176 96z"
            />
          </svg>
        </span>
      </h1>
      <hr />
      <form
        action=""
        onSubmit={loginUser}
        className="
        ml-3 mr-3 max-w-sm
        [&>label>input]:bg-custom-form-bg
        [&>label>input]:border
        [&>label>input]:border-gray-300
        [&>label>input]:text-gray-900
        [&>label>input]:text-sm
        [&>label>input]:rounded-lg
        [&>label>input]:p-2.5
        [&>label>input]:block
        [&>label>input]:w-full
        [&>label>input:focus]:outline-custom-form-outline
        "
      >
        <label
          className="block text-sm font-medium text-gray-900"
        >
          Uporabnik:
          <input
            required
            autoFocus
            type="text"
            name="username"
          />
        </label>
        <label
          className="block text-sm font-medium text-gray-900"
        >
          Geslo:
          <input
            required
            type="password"
            name="password"
          />
        </label>
        <button type="submit" className="custom-button mt-4 !px-8">Vpis</button>
      </form>
    </div>
  )
}
export default LoginPage