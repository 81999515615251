import React, {useContext, useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import TableOfChecks from './components/TableOfChecks';
import LoginLogout from "./components/LoginLogout";

const ServiceFireExtinguisher = () => {

  const [url, setUrl] = useState("/serviceFEx-api-v1/checks/");

  return (
    <div>
      {/* <div className="absolute bottom-0 right-0">+</div> */}
      <h3>
        <Link
          // className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline"
          className="custom-button fixed bottom-3 right-5 outline outline-3 shadow-xl"
          to="/serviceFEx/check"
        >
          <span className="inline-flex items-center align-middle relative -top-[0.125rem]">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </span>
        </Link>
      </h3>
      <div className="flex justify-between">
        <h1 className="text-4xl sm:text-6xl sticky">
          Servis RGA
          <span className="inline-flex items-center align-middle relative -top-2 ml-4">
            <svg
              className="w-8 h-8 sm:w-12 sm:h-12 fill-custom-table-header"
              viewBox="0 0 512 512"
              xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M64 480c0 17.67 14.33 32 31.1 32H256c17.67 0 31.1-14.33 31.1-32l-.0001-32H64L64 480zM503.4 5.56c-5.453-4.531-12.61-6.406-19.67-5.188l-175.1 32c-11.41 2.094-19.7 12.03-19.7 23.63L224 56L224 32c0-17.67-14.33-32-31.1-32H160C142.3 0 128 14.33 128 32l.0002 26.81C69.59 69.32 20.5 110.6 1.235 168.4C-2.952 181 3.845 194.6 16.41 198.8C18.94 199.6 21.48 200 24 200c10.05 0 19.42-6.344 22.77-16.41C59.45 145.5 90.47 117.8 128 108L128 139.2C90.27 157.2 64 195.4 64 240L64 416h223.1l.0001-176c0-44.6-26.27-82.79-63.1-100.8L224 104l63.1-.002c0 11.59 8.297 21.53 19.7 23.62l175.1 31.1c1.438 .25 2.875 .375 4.297 .375c5.578 0 11.03-1.938 15.37-5.562c5.469-4.562 8.625-11.31 8.625-18.44V23.1C511.1 16.87 508.8 10.12 503.4 5.56zM176 96C167.2 96 160 88.84 160 80S167.2 64 176 64s15.1 7.164 15.1 16S184.8 96 176 96z"
              />
            </svg>
          </span>
        </h1>
        <LoginLogout />
      </div>
      <hr />
      <div 
        data-function="container-for-title-and-buttons"
        className="flex justify-between items-center"
      >
        <h2 className="text-3xl align-bottom">Nazadnje pregledani gasilniki:</h2>
        <div
          data-function="container-for-buttons-on-right"
          className="
            flex
            justify-end
            [&>*]:mx-1
            [&>*]:my-4
          "
        >
          {/* md:flex-col */}

          {/* <h3>
            <Link
              // className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline"
              className="custom-button"
              to="/serviceFEx/check"
            >
              <span className="inline-flex items-center align-middle relative -top-[0.125rem] mr-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </span>
              Dodaj pregled ali prodajo RGA
            </Link>
          </h3> */}

          <h3>
            <Link
              className="custom-button"
              to="/serviceFEx/review"
            >
              <span className="inline-flex items-center align-middle relative -top-[0.125rem]">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 15.75l-2.489-2.489m0 0a3.375 3.375 0 10-4.773-4.773 3.375 3.375 0 004.774 4.774zM21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </span>
              {/* Ogled pregledov s filtri */}
            </Link>
          </h3>

          <h3>
            <Link
              className="custom-button"
              to="/serviceFEx/review/ownerFExs"
            >
              <span className="inline-flex items-center align-middle relative -top-[0.125rem]  !mr-0">
                {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg> */}
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                </svg>

              </span>
              {/* Pregled gasilnikov po lastnikih */}
            </Link>
          </h3>
        </div>
      </div>
      
      <TableOfChecks url={url} />

      

    </div>
  )
}

export default ServiceFireExtinguisher