import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams, useNavigate } from "react-router-dom"
import TableOfChecks from "./components/TableOfChecks"
import useAxios from './utils/useAxios'
import Select from 'react-select'

const ServicemanChecks = () => {
    // const serviceman = useParams().serviceman ?? ""
    const [serviceman, setServiceman] = useState(useParams().serviceman ?? "")
    const [servicemen, setServicemen] = useState([])
    let [checks, setChecks] = useState([])
    const [url, setUrl] = useState();
    const selectServicemanRef = useRef(null);
    const navigate = useNavigate();

    let api = useAxios()

    // style --------------------------
    const styleLabel = "text-sm font-medium text-gray-900 "
    const styleSelect = {
        control: () => "!bg-custom-form-bg h-11 mb-3",
        menu: () => "bg-slate-200 ",
        dropdownIndicator: () => "!text-gray-600",
        // placeholder: () => "!text-red-500",
        singleValue: () => "!text-gray-900 text-sm",
        // option: () => "hover:bg-yellow-500",
    }

    // Servicemen ---------------
    useEffect ( () => {
        getServicemen()
    }, []);
    let getServicemen = async () => {
        try {
            let response = await api.get('/serviceFEx-api-v1/servicemen')
            if (response.status === 200) {
                let data = response.data
                setServicemen(data)
            }
        } catch (error) {
            console.error("An error occurred:", error.message);
        }
    }
    // let getServicemen = async () => {
    //     // console.log("get serviceman")
    //     let response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/serviceFEx-api-v1/servicemen`)
    //     let data = await response.json()
    //     console.log(data)
    //     setServicemen(data)
    // }
    useEffect ( () => {
        serviceman && selectServicemanRef.current.setValue({ value: serviceman, label: serviceman });
    })

    // Checks -------------------
    useEffect ( () => {
        // getChecks();
        setUrl("/serviceFEx-api-v1/checks?serviceman=" + serviceman)
        navigate(`/serviceFEx/review/serviceman/${serviceman}`);
    }, [serviceman])
    // let getChecks = async () => {
    //     try {
    //         // let response = await fetch (`http://127.0.0.1:8000/serviceFEx-api-v1/checks`) //?serviceman=${serviceman}`)
    //         let response = await api.get(`/serviceFEx-api-v1/checks?serviceman=${serviceman}`)
    //         // let response = await fetch (`http://127.0.0.1:8000/serviceFEx-api-v1/checks?serviceman=admin`)
    //         if (response.status === 200) {
    //             setChecks(response.data)
    //         }
    //     } catch (error) {
    //         console.error("An error occurred:", error.message);
    //     }
    // }

    return (
        <div className="mx-2">
            <div className="max-w-sm">
                <label className={styleLabel}  htmlFor="serviceman">Serviser:</label>
                <Select
                    options={servicemen.map(serviceman => ({ label: serviceman.username, value: serviceman.username }))}
                    className={styleSelect}
                    ref={selectServicemanRef}
                    onChange={(selectedOption) => setServiceman(selectedOption.value)}
                    inputId="serviceman"
                />
            </div>
            <hr className="mt-3" />
            <hr />
            <TableOfChecks url={url} />
        </div>
    )
}

export default ServicemanChecks