import React, { useState } from 'react';
import Select from 'react-select';

const YourComponent = () => {
  const [selectedOwner, setSelectedOwner] = useState(null);

  const handleChangeOwner = (selectedOption) => {
    setSelectedOwner(selectedOption);
    // Handle any additional logic here
  };

  const onClear = () => {
    setSelectedOwner(null);
  };

  const owners = ["Option1", "Option2", "Option3"];

  return (
    <div>
      <Select
        options={owners.map(option => ({ label: option, value: option }))}
        onChange={handleChangeOwner}
        name="owner"
        id="owner"
        placeholder={<div>Izberi...</div>}
        isClearable
        value={selectedOwner}
      />
      <button onClick={onClear}>Clear Value</button><br />
    </div>
  );
};

export default YourComponent;
