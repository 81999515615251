import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import TableOfChecks from './components/TableOfChecks';
import useAxios from './utils/useAxios'

{/* cSpell:ignore Lastnik gasilnikov objekt največ preteče odstotek vseh */}

const OwnerFExs = () => {
  const owner = useParams().owner
  const object = useParams().object

  let [expiredFExPerOwner, setExpiredFExPerOwner] = useState([])
  // let [checks, setChecks] = useState([])
  const [url, setUrl] = useState();

  let api = useAxios()

  useEffect (() => {
    getOwners()
  }, [])
  let getOwners = async () => {
    try {
      let response = await api.get('/serviceFEx-api-v1/expiredFExPerOwner')
      if(response.status === 200) {
        let data = response.data
        setExpiredFExPerOwner(data)
      }
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  }
  // let getOwners = async () => {
  //   let response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/serviceFEx-api-v1/expiredFExPerOwner`)
  //   let data = await response.json()
  //   // console.log("Owners: ", data)
  //   setExpiredFExPerOwner(data)
  // }


  useEffect (() => {
    // console.log("get checks")
    if (owner && owner.length > 0) {
      getUrl();
    }
  }, [owner, object])
  let getUrl = async () => {
    let query = "/serviceFEx-api-v1/checks"
    if (owner) {
      query += "?owner=" + owner;
      if (object) {
        query += "&object=" + object;
      }
    }

    setUrl(query)

    // let response = await fetch(`http://127.0.0.1:8000/serviceFEx-api-v1/checks?owner=${owner}&object=${object}`)
    // let response = await fetch(`http://127.0.0.1:8000/serviceFEx-api-v1/checks?owner=sometext`)
    // let response = await fetch(`http://127.0.0.1:8000/serviceFEx-api-v1/checks?owner=Eurosol`)

    // try {
    //   let response = await api.get(query)
    //   // let data = await response.json()
    //   // console.log("Checks from one owner: ", data)
    //   if (response.status === 200) {
    //     setChecks(response.data)
    //   }
    // } catch (error) {
    //   console.error("An error occurred:", error.message);
    // }
    // console.log(checks.length)
  }

  let [ownerObjects, setOwnerObjects] = useState([])
  useEffect (() => {
    if (owner && owner.length > 0) {
      getOwnerObjects()
      }
  }, [owner])
  let getOwnerObjects = async () => {
    try {
      let response = await api.get(`/serviceFEx-api-v1/owners/${owner}/objects/`)
      if (response.status === 200) {
        let data = response.data
        setOwnerObjects(() => data)
      }
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  }
  
  // let getOwnerObjects = async () => {
  //   let response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/serviceFEx-api-v1/owners/${owner}/objects/`)
  //   let data = await response.json()
  //   // console.log("Owner objects:", data)
  //   setOwnerObjects(() => data)
  // }

  // const history = useHistory()
  // const handleRowClick = (ownerID) => {
  //   history.push(`/serviceFEx/review/ownerFExs/${ownerID}`)
  // }



  const handleTrClick = (owner_name) => {
    // console.log("click, click on ", owner_name)
    window.location.href = `ownerFExs/${owner_name}`
    // const url = `www.google.com`; // Modify this URL pattern according to your needs
    // history.push(url);
  }

  return (
    <div className="m-2">
      {owner ? (
          <>
            Lastnik gasilnikov:
            <br />
            <div className="flex">
              <Link to={`/serviceFEx/review/ownerFExs/${owner}`} className="custom-button">{owner}</Link>
            </div>
            Objekt/i:
            <br />
            <div className="
              flex
              flex-wrap
              [&>*]:mr-2
              [&>*:first-child]:ml-0
              [&>*]:my-1
            ">
              {
                ownerObjects.length == 1 && ownerObjects[0].length == 0 ?
                  <span>Nobenemu gasilniku ni dodeljen objekt.</span>
                  :
                  ownerObjects.map((object, index) => (
                      object &&
                        <div key={index} className="custom-button">
                          <Link to={`/serviceFEx/review/ownerFExs/${owner}/${object}`}>{object}</Link>
                        </div>
                    ))
              }
            </div>
            {/* {owner_objects.map((item, index) => (
              <div key={index}>
                <h3>{Object.keys(item)[0]}</h3>
                <ul>
                  {item[Object.keys(item)[0]].map((value, valueIndex) => (
                    <li key={valueIndex}>{value}</li>
                  ))}
                </ul>
              </div>
            ))} */}
            {/* {console.log("Print out from return: ", owner_objects)} */}
            <TableOfChecks url={url} />
          </>
        ) : (
          <div>
            <div className="flex justify-center">
              {/* <p>Owner parameter in URL is not found</p> */}
              <table className="text-center border-collapse">
                <thead className="bg-custom-table-header text-me border-2 border-custom-table-border text-white sticky top-1">
                  <tr className='[&>*]:border-custom-table-border [&>*]:border-1 [&>*]:p-2'>
                    <th className="border border-collapse">Največ gasilnikov  <br/> preteče dne</th>
                    <th className="border border-collapse">Odstotek gasilnikov</th>
                    <th className="border border-collapse">Lastnik</th>
                  </tr>
                </thead>
                <tbody>
                    {expiredFExPerOwner.map((owner, index) => (
                      // <tr onClick={() => handleRowClick(owner.name)} style={{ cursor: 'pointer' }}>
                      <tr
                        key={index}
                        onClick={() => handleTrClick(owner.name)}
                        className="
                          cursor-pointer
                          hover:scale-105
                          odd:bg-custom-table-child-odd-bg odd:hover:bg-gray-200
                          even:bg-custom-table-child-even-bg even:hover:bg-gray-200
                          [&>*]:border-custom-table-border [&>*]:border-1 [&>*]:p-2
                "
                      >
                        <td className="border border-collapse">{owner.max_day.date}</td>
                        <td className="border border-collapse">{owner.max_day.percentage} % ({owner.max_day.checks} / {owner.max_day.all_checks}  gas.)</td>
                        <td className="border border-collapse">{owner.name}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {/* {owners.map((owner, index) => (
                <p key={index}><Link to={`/serviceFEx/review/ownerFExs/${owner.name}`}>{owner.name}</Link> <span>{owner.max_day.date} {owner.max_day.checks} {owner.max_day.percentage} % vseh gasilnikov</span></p>
              ))} */}
            </div>
          </div>
        )
      }
    </div>
  )
}

export default OwnerFExs