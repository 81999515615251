import React, {useEffect, useState} from 'react'
import { Link, useParams } from 'react-router-dom'
import TableOfChecks from './components/TableOfChecks'
import useAxios from './utils/useAxios'

const Manufacturer = () => {
  const manufacturer = useParams().manufacturer ?? ""
  const [manufacturers, setManufacturers] = useState([]);
  const [url, setUrl] = useState();
  let [checks, setChecks] = useState([])
  let api = useAxios()
  
  useEffect(() => {
      // getChecks();
      setUrl("/serviceFEx-api-v1/checks?manufacturer=" + manufacturer)
  }, [manufacturer]);
  // let getChecks = async () => {
  //   try {
  //     let response = await api.get(`/serviceFEx-api-v1/checks?manufacturer=${manufacturer}`)
  //     // let response = await fetch(`http://127.0.0.1:8000/serviceFEx-api-v1/checks?manufacturer=${manufacturer}`)
  //     // let data = await response.json()
  //     if (response.status === 200) {
  //       setChecks(response.data)
  //     }
  //   } catch (error) {
  //     console.error("An error occurred:", error.message);
  //   }
  // }

    // Manufacturers --------------------
  useEffect(() => {
      getManufacturers();
  }, [])
  let getManufacturers = async () => {
    try {
      let response = await api.get(`${process.env.REACT_APP_API_ENDPOINT}/serviceFEx-api-v1/manufacturers`)
      if (response.status === 200) {
        setManufacturers(response.data)
      }
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  }

  return (
    <div className="px-2">
      <div className='flex flex-wrap'>
        {manufacturers.map((manufacturer, index) => (
          <Link to={`/serviceFEx/review/FEx/manufacturer/${manufacturer.name}`} key={index}>
            <div className="custom-button w-32 my-1 mr-2 border-1 flex items-center justify-center" key={index}>
              {manufacturer.name}
            </div>
          </Link>
        ))}
      </div>
      Tukaj so pregledi za proizvajalca: {manufacturer}
      {/* <Link
            className="custom-table-link"
            to={`/serviceFEx/review/FEx/manufacturer`}>
              -- Vsi pregledi
          </Link> */}
      <TableOfChecks url={url} />
    </div>
  )
}

export default Manufacturer