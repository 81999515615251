import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePageMain from './pages/home_page/HomePageMain';
import ServiceFEx from './pages/service_f_ex/ServiceFExMain';
import Review from './pages/service_f_ex/Review';
import Check from './pages/service_f_ex/Check';
import HeaderFEx  from './pages/service_f_ex/components/Header';
import FireExtinguisher from './pages/service_f_ex/FireExtinguisher';
import OwnerFExs from './pages/service_f_ex/OwnerFExs';
// import ObjectFExs from './pages/service_f_ex/ObjectFExs';
import DateFEx from './pages/service_f_ex/DateFEx';
import Test from './pages/service_f_ex/Test';
import LoginPage from "./pages/service_f_ex/LoginPage";
import PrivateRoute from "./pages/service_f_ex/utils/PrivateRoute";
import Test2 from "./pages/service_f_ex/Test2";
import Test3 from "./pages/service_f_ex/Test3";
import Test4 from "./pages/service_f_ex/Test4";
import { AuthProvider } from "./pages/service_f_ex/context/AuthContext";
import Manufacturer from './pages/service_f_ex/Manufacturer';
import ProductionYear from './pages/service_f_ex/ProductionYear';
import ServicemanChecks from "./pages/service_f_ex/ServicemanChecks";

function App() {
  return (
    <div className="m-2">
      <BrowserRouter basename="/">
        <AuthProvider>
          <Routes>
            <Route path="/" exact element={<HomePageMain />} />
            <Route element={<PrivateRoute />}>
              <Route path="test1" element={<><HeaderFEx /><Test /></>} />
              <Route path="test2" element={<><HeaderFEx /><Test2 /></>} />
              <Route path="test3" element={<><HeaderFEx /><Test3 /></>} />
              <Route path="test4" element={<><HeaderFEx /><Test4 /></>} />
            </Route>
            {/* <Route path="/" exact element={<HomePageMain />} /> */}
            <Route path="serviceFEx" >
              <Route path="login" element={<><LoginPage /></>} />
              <Route element={<PrivateRoute />}>
                <Route index element={<ServiceFEx />} />
                <Route path="check/:sn?/:year?/:check_id?" element={<><HeaderFEx /><Check /> </>} />
                <Route path="review" >
                  <Route index element={<><HeaderFEx /><Review /> </>} />
                  <Route path="FEx/:sn/:production_year" element={<><HeaderFEx /><FireExtinguisher /></>} />
                  <Route path="ownerFExs/:owner?/:object?" element={<><HeaderFEx /><OwnerFExs /></>} />
                  {/* <Route path="objectFExs/:object?" element={<><HeaderFEx /><ObjectFExs /></>} /> */}
                  <Route path="date/:date" element={<><HeaderFEx /><DateFEx /></>}></Route>
                  <Route path="FEx/manufacturer/:manufacturer?" element={<><HeaderFEx /><Manufacturer /></>}></Route>
                  <Route path="FEx/production-year/:year?" element={<><HeaderFEx /><ProductionYear /></>}></Route>
                  <Route path="serviceman/:serviceman?" element={<><HeaderFEx /><ServicemanChecks /></>}></Route>
                </Route>
              </Route>
            </Route>
            <Route path="*" element={<h1>Page not fund</h1>}/>
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;