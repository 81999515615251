import React, {useEffect, useState} from 'react'
import { Link, useParams } from 'react-router-dom'
import TableOfChecks from './components/TableOfChecks'
// import useAxios from './utils/useAxios'

const DateFEx = () => {
  let paramDate = useParams().date

  let [date, setDate] = useState(new Date(paramDate))
  // let [checks, setChecks] = useState([])
  const [url, setUrl] = useState();
  // let [previousDate, setPreviousDate] = useState()
  // let [nextDate, setNextDate] = useState()
  
  // let previousDate = new Date(date)
  // previousDate.setDate(date.getDate()-1)
  // let nextDate = new Date(date)
  // nextDate.setDate(date.getDate()+1) // QUESTION if I use `getDate` instead of `getTime` the button for next day stop working on `2023-03-26`. Why?

  let previousDate = new Date(date.getTime() - (24*60*60*1000));
  let nextDate = new Date(date.getTime() + (24*60*60*1000));


  // let api = useAxios()

  // date2.setDate(date2.getDate() + 2)
  //       date2.toLocaleDateString()

  useEffect(() => {
      // getChecks();
      setUrl("/serviceFEx-api-v1/checks?maintenance_date=" + paramDate)
      setDate(new Date(paramDate))
  }, [paramDate]);
  // let getChecks = async () => {
  //   try {
  //     let response = await api.get(`/serviceFEx-api-v1/checks?maintenance_date=${paramDate}`)
  //     // let response = await fetch(`http://127.0.0.1:8000/serviceFEx-api-v1/checks?maintenance_date=${paramDate}`)
  //     // https://developer.mozilla.org/en-US/docs/Web/API/fetch
  //     // let data = await response.json()
  //     if (response.status === 200) {
  //       setChecks(response.data)
  //     }
  //   } catch (error) {
  //     console.error("An error occurred:", error.message);
  //   }
  // }

  // const handlePreviousNext = (event) =>{
  //   const {name} = event.target
  //   if (name === "previous" ) {
  //     window.history.replaceState(null, null, "pathname/goes/here")
  //   } else if (name === "next") {
  //     window.history.replaceState(null, null, "pathname/goes/here")
  //   }
  // }


  return (
    <div>
      <div className="
        flex
        flex-wrap
        [&>*]:mx-1
        [&>*:first-child]:ml-0
        [&>*:last-child]:mr-2
        [&>*]:my-4
      ">
        <Link
          to={`../date/${previousDate.toISOString().split('T')[0]}`}
          className="custom-button flex !pr-2"
        >
          <span className="items-center align-middle relative mr-2 ">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </span>
          Prejšnji datum 
          <span
            className="p-1.5 text-xs font-medium  bg-custom-table-badge rounded-lg tracking-wider bg-opacity-70 shadow-sm ml-2 !w-24 text-center"
          >
            {previousDate.toLocaleDateString()}
          </span>
        </Link>
        <Link
          to={`../date/${nextDate.toISOString().split('T')[0]}`}
          className="custom-button flex !pr-2"
        >
          <span className="items-center align-middle relative mr-2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </span>
          Naslednji datum
          <span
            className="p-1.5 text-xs font-medium  bg-custom-table-badge rounded-lg tracking-wider bg-opacity-70 shadow-sm ml-2 !w-24  text-center"
          >
            {nextDate.toLocaleDateString()}
          </span>
        </Link>
      </div>
      Tukaj so pregledi za dan: <span className="underline font-bold">{date.toLocaleDateString()}</span>
      <TableOfChecks url={url} />
    </div>
  )
}

export default DateFEx
