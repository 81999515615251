import React, { useContext } from 'react'
import { Link } from "react-router-dom";
import AuthContext from '../context/AuthContext';

const LoginLogout = () => {
  let {user, logoutUser} = useContext(AuthContext)

  return (
  <div className="flex flex-col justify-end">
    <p className='text-lg'>Vpisan: {user ? user.username : "Nihče"} </p>
    {user ? 
      <button onClick={logoutUser} className='inline-flex outline outline-custom-button-main rounded px-3 py-1 m-1'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
        </svg>
        Izpis
      </button>
      :
      <Link to="/serviceFEx/login" className='inline-flex outline outline-custom-button-main rounded px-3 py-1 m-2'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 rotate-180 mr-2">
          <path stroke-lLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
        </svg>
        Vpis
      </Link>
    }
  </div>
  )
}

export default LoginLogout