import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
// import AuthContext from "../context/AuthContext";
import LoginLogout from "./LoginLogout";

const Header = () => {
    // let {user, logoutUser} = useContext(AuthContext)
    return (
        <>
            <div className="ml-2 mb-1 flex justify-between items-center sticky">
                <Link to="/serviceFEx">
                    <div className="text-4xl sm:text-6xl mt-2">
                        <span className="inline-flex items-center align-middle relative -top-2">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="stroke-current text-custom-table-header w-11 h-11">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
                            </svg>
                        </span>
                        Domov
                    </div>
                </Link>
                <span className=''>
                    <LoginLogout />
                </span>
            </div>
            <hr />
        </>
    )
}

export default Header;