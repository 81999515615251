import React, {useState, useEffect, useRef, useContext} from 'react';
import { useParams } from 'react-router-dom';
import AuthContext from './context/AuthContext'
// import axiosInstance from './utils/axiosInstance'
import useAxios from './utils/useAxios'
import { toast } from 'react-toastify';

{/* cSpell:ignore tesnilo glave poraba materiala prah + vnesi vsaj vrsto pregleda prišlo napake kontaktiraj administratorja
cSpell:ignore pregled vnešen preveri podatke shranjen dodajanje prodaje gasilnik serijsko številko letom proizvodnje obstaja 
cSpell:ignore mystyle spremeni ročno serijska številka proizvajalec vrsta lastnik objekt lokacija opomba gasilnika vzdrževanja veljavno
cSpell:ignore porabljen enota količina izdelave vzdrževanja veljavnost veljavnosti uporabljeni proizvajalci serviser odstrani dodaj potrdi
cSpell:ignore pravilno proizvajalca izberi */}

const Check = () => {
  const sn = useParams().sn
  const year = useParams().year
  const checkID=useParams().check_id
  const [showSnDropdown, setShowSnDropdown] = useState(false);
  const [showMaterialDropdown, setShowMaterialDropdown] = useState(null);
  const [showManufacturerDropdown, setShowManufacturerDropdown] = useState(false);
  const [showOwnerDropdown, setShowOwnerDropdown] = useState(true);

  const snInput = useRef(null);
  const snDropdownRef = useRef(null);
  const materialDropdownRef = useRef(null);
  const materialDropdownLineRef = useRef(null);
  const inputMaterialUnitRef = useRef(null);
  const inputMaterialQuantityRef = useRef(null);
  const manufacturerDropdownRef = useRef(null);

  const [manufacturers, setManufacturers] = useState([]);
  const [maintenanceTypes, setMaintenanceTypes] = useState([]);
  const [fexsSnYear, setFexsSnYear] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [owners, setOwners] = useState([])

  const [formSN, setFormSN] = useState('');
  const [formProductionYear, setFormProductionYear] = useState(0);
  const [formManufacturer, setFormManufacturer] = useState('');
  const [formType, setFormType] = useState('');
  const [formMaintenanceTypes, setFormMaintenanceTypes] = useState([]);
  const [formOwner, setFormOwner] = useState('');
  const [formObject, setFormObject] = useState('');
  const [formLocation, setFormLocation] = useState('');
  const [formFexNote, setFormFexNote] = useState('');
  const today = new Date();
  const [formMaintenanceDate, setFormMaintenanceDate] = useState(today.toISOString().slice(0, 10));
  const after_one_year = new Date()
  after_one_year.setFullYear(today.getFullYear() + 1)
  const [formValidityDate, setFormValidityDate] = useState(after_one_year.toISOString().slice(0, 10));
  const [formUsedMaterial, setFormUsedMaterial] = useState([]);
  const [formCheckNote, setFormCheckNote] = useState('');

  const [errorDateValiditySmallerThanMaintenance, setErrorDateValiditySmallerThanMaintenance] = useState(null);
  
  let {user, logoutUser} = useContext(AuthContext)

  let api = useAxios()

  const styleErrorMessage = "text-red-500 text-sm transform -translate-y-2 "


  // SN -------------------------------
  // useEffect(() => {
  //   getFexsSnYear();
  // }, []);
  // let getFexsSnYear = async () => {
  //   let response = await fetch(`http://127.0.0.1:/serviceFEx-api-v1/fexs-sn-year`)
  //   // let response = await fetch("http://127.0.0.1://")
  //   let data = await response.json()
  //   setFexsSnYear(data)
  // }
  useEffect(() => {
    getFexsSnYear();
  }, []);
  let getFexsSnYear = async () => {
    if (sn && year){
      try {
        let response = await api.get((`/serviceFEx-api-v1/fexs-sn-year?sn=${sn}&year=${year}`))
        if (response.status === 200) {
          let fexData = response.data
          if (fexData.length === 1) {
            setFormSN(fexData[0].sn)
            setFormProductionYear(fexData[0].production_year)
            setFormManufacturer(fexData[0].manufacturer.name)
            setFormType(fexData[0].type)
            fexData[0]?.owner?.name && setFormOwner(fexData[0].owner.name)
            fexData[0]?.object && setFormObject(fexData[0].object)
            fexData[0]?.location && setFormLocation(fexData[0].location)
            fexData[0]?.note && setFormFexNote(fexData[0].note)
          }
          if (checkID) {
            let response = await api.get(`/serviceFEx-api-v1/check/?check_id=${checkID}`)
            if (response.status === 200) {
              if (response.data.fire_extinguisher.production_year === fexData[0].production_year &&
                response.data.fire_extinguisher.sn === fexData[0].sn) {
                setFormMaintenanceDate(response.data.maintenance_date)
                setFormValidityDate(response.data.validity)
                response.data.used_material.map((material) => {
                  // console.log(material.material.name)
                  setFormUsedMaterial([...formUsedMaterial, {material:material.material.name, unit:material.material.unit, quantity:material.quantity}])
                })
                setFormMaintenanceTypes(response.data.maintenance_type)
                var maintenance_types = []
                response.data.maintenance_type.map((maintenance_type) => {
                  maintenance_types.push(maintenance_type.type)
                })
                setFormMaintenanceTypes(maintenance_types);
                setFormCheckNote(response.data.note)
              }
            }
          }
        }
      } catch (error) {
        console.error("An error occurred:", error.message);
      }
    }
  }
  // const handleChangeSn = async (event) => {
  //   setFormSN(event.target.value);
  //   // console.log(event.target.value);
  //   // console.log("formSN: ", formSN)
  // }
  // useEffect (()=> {
  //   console.log("**********************************")
  //   console.log("formSN inside useEffect: ", formSN)
  //   const fetchData = async () => {
  //     const inputData = formSN.toLocaleLowerCase()
  //     console.log("inputData: ", inputData)
  //     if (inputData) {
  //       console.log("inside if")
  //       let response = await fetch(`http://127.0.0.1:8000/serviceFEx-api-v1/fexs-sn-year?sn=${inputData}`)
  //       let data = await response.json()
  //       setFexsSnYear(data)
  //       console.log("data.length: ", data.length)
  //       if (data.length > 0) {
  //         console.log("Inside If")
  //         setShowSnDropdown(true)
  //       } else {
  //         setShowSnDropdown(false)
  //       }
  //     } else {
  //     setFexsSnYear([])
  //     }
  //   }
  //   fetchData();
  // }, [formSN])
  const handleChangeSn = async (event) => {
    // if 'e' is not in focus, just hide the dropdown and return
    if (!(event.target === document.activeElement)) {
      setShowSnDropdown(false)
      return
    }
    setFormSN(event.target.value);
    // setFormProductionYear("")
    const inputData = event.target.value.toLowerCase()
    if (inputData) {
      try {
        let response = await api.get(`/serviceFEx-api-v1/fexs-sn-year?sn=${inputData}`)
        if (response.status === 200) {
          setFexsSnYear(response.data)
          if (response.data.length > 0) {
            setShowSnDropdown(true)
          } else {
            setShowSnDropdown(false)
          }
        }
      } catch (error) {
        console.error("An error occurred:", error.message);
      }
    } else {
      setShowSnDropdown(false)
    }
    //   // let response = await fetch(`http://127.0.0.1:8000/serviceFEx-api-v1/fexs-sn-year?sn=1234`)
    //   let response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/serviceFEx-api-v1/fexs-sn-year?sn=${inputData}`)
    //   let data = await response.json()
    //   setFexsSnYear(data)
    //   // console.log(data)
    //   if (data.length > 0) {
    //     setShowSnDropdown(true)
    //   } else {
    //     setShowSnDropdown(false)
    //   }
    // } else {
    //   // setFexsSnYear([])
    //   setShowSnDropdown(false)
    // }
  }
  useEffect(() => { // focus on input SN field when page is loaded
    if (snInput.current) {
      snInput.current.focus();
    }
  }, []);
  useEffect(() => {
  // Add event listener to close dropdown when clicking outside
  const handleOutsideClick = (event) => {
    if (snDropdownRef.current && !snDropdownRef.current.contains(event.target)) {
      setShowSnDropdown(false);
      document.removeEventListener('mousedown', handleOutsideClick);
    }
  };
  if (showSnDropdown) {
    document.addEventListener('mousedown', handleOutsideClick);
  }    
  return () => {
    document.removeEventListener('mousedown', handleOutsideClick);
  };
}, [showSnDropdown]);

  // Manufacturers --------------------
  // useEffect(() => {
  //   if (authTokens){
  //     getManufacturers();
  //   }
  // }, [authTokens, loading])
  useEffect (() => {
    getManufacturers();
  }, [])
  let getManufacturers = async () => {
    // let response = await fetch(`http://127.0.0.1:8000/serviceFEx-api-v1/manufacturers`)

    // let authTokens = localStorage.getItem("authTokens") ? JSON.parse(localStorage.getItem("authTokens")) : null
    // let response = await fetch(`http://127.0.0.1:8000/serviceFEx-api-v1/manufacturers`, {
    //   method:"GET",
    //   headers:{
    //     "Content-Type":"application/json; charset=utf-8",
    //     "Authorization":"Bearer " + String(authTokens.access)
    //   }
    // })
    // let data = await response.json()
    // if (response.status === 200) {
    //   setManufacturers(data)
    // } 
    
    // let response = await axiosInstance.get('/serviceFEx-api-v1/manufacturers/')
    try {
      let response = await api.get('/serviceFEx-api-v1/manufacturers/')
      if (response.status === 200) {
        setManufacturers(response.data)
      }
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
    // else if (response.statusText === 'Unauthorized') {
    //   logoutUser()
    // }

  }
//   const handleChangeManufacturerOld = (event) => {
//     // if 'e' is not in focus, just hide the dropdown and return
//     if (!(event.target === document.activeElement)) {
//       setShowManufacturerDropdown(false)
//       return
//     }
//     setFormManufacturer(event.target.value);
//     // if (event.target.value.length > 0) {
//     //   setShowManufacturerDropdown(true)
//     // } else {
//     //   setShowManufacturerDropdown(false)
//     // }
//     setShowManufacturerDropdown(true)
//   }
//   useEffect(() => {
//   // Add event listener to close dropdown when clicking outside
//   const handleOutsideClick = (event) => {
//     if (manufacturerDropdownRef.current && !manufacturerDropdownRef.current.contains(event.target)) {
//       setShowManufacturerDropdown(false);
//       document.removeEventListener('mousedown', handleOutsideClick);
//     }
//   };
//   if (showManufacturerDropdown) {
//     document.addEventListener('mousedown', handleOutsideClick);
//   }    
//   return () => {
//     document.removeEventListener('mousedown', handleOutsideClick);
//   };
// }, [showManufacturerDropdown]);

useEffect(() => {
// Add event listener to close dropdown when clicking outside
const handleOutsideClick = (event) => {
  if (manufacturerDropdownRef.current && !manufacturerDropdownRef.current.contains(event.target)) {
    setShowManufacturerDropdown(false);
    document.removeEventListener('mousedown', handleOutsideClick);
  }
};
if (showManufacturerDropdown) {
  document.addEventListener('mousedown', handleOutsideClick);
}    
return () => {
  document.removeEventListener('mousedown', handleOutsideClick);
};
}, [showManufacturerDropdown]);

  // Maintenance types ----------------
  useEffect(() => {
    getMaintenanceTypes();
  }, []);
  let getMaintenanceTypes = async () => {
    try {
      let response = await api.get('/serviceFEx-api-v1/maintenance_types')
      if (response.status === 200) {
        let data = response.data
        setMaintenanceTypes(data)
      }
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  }
  // let getMaintenanceTypes = async () => {
  //   let response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/serviceFEx-api-v1/maintenance_types`)
  //   // let response = await fetch("http://127.0.0.1:8000/serviceFEx-api-v1/checks/")
  //   let data = await response.json()
  //   setMaintenanceTypes(data)
  // }
  const handleChangeFormMaintenanceTypes = (event) => {
    const {value, checked} = event.target;
    // console.log(value)
    if (checked) {
      setFormMaintenanceTypes([...formMaintenanceTypes, value]);
    } else {
      setFormMaintenanceTypes(formMaintenanceTypes.filter((maintenanceType) => maintenanceType !== value));
    }
  };

  // Used material --------------------
  useEffect(() => {
    getMaterials();
  }, [])
  let getMaterials = async () => {
    try {
      let response = await api.get('/serviceFEx-api-v1/materials')
      if (response.status === 200) {
        let data = response.data
        setMaterials(data)
      }
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  }
  // let getMaterials = async () => {
  //   let response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/serviceFEx-api-v1/materials`)
  //   let data = await response.json()
  //   setMaterials(data)
  // }
  const handleAddMaterial = () => {
    setFormUsedMaterial([...formUsedMaterial, {material:"", unit:"", quantity:""}])
    // setFormUsedMaterial([...formUsedMaterial, {}])
  }
  const handleChangeMaterial = (e, index) => {
    // if 'e' is not in focus, just hide the dropdown and return
    if (!(e.target === document.activeElement)) {
      setShowMaterialDropdown(null)
      return
    }
    const {name, value}=e.target
    const onChangeVal = [...formUsedMaterial]
    onChangeVal[index][name]=value
    // setFormUsedMaterial(onChangeVal)
    if (name === 'material') { // it could be 'material', 'unit' or 'quantity'
      setShowMaterialDropdown(value ? index : null)
      if (materials.some(item => item.name.toLowerCase() === value.toLowerCase())) { // formUsedMaterial[i][name].toLowerCase())) {
        onChangeVal[index]['unit'] = materials.find(item => item.name.toLowerCase() === value.toLowerCase()).unit // formUsedMaterial[i][name].toLowerCase())
        inputMaterialUnitRef.current.disabled = true;
        setShowMaterialDropdown(null)
      } else {
        onChangeVal[index]['unit'] = ""
        inputMaterialUnitRef.current.disabled = false;
      }
    }
    setFormUsedMaterial(onChangeVal)
  }
  const handleDeleteMaterial = (i) => {
    const deleVal = [...formUsedMaterial]
    deleVal.splice(i, 1)
    setFormUsedMaterial(deleVal)
  }
  useEffect(() => {
    // Add event listener to close dropdown when clicking outside
    const handleOutsideClick = (event) => {
      // console.log(materialDropdownRef.current)
      if (materialDropdownRef.current && !materialDropdownRef.current.contains(event.target)) {
        setShowMaterialDropdown(null);
        // setTimeout(function() {
        //   setShowMaterialDropdown(false);
        // }, 30);
        document.removeEventListener('mousedown', handleOutsideClick);
      // console.log("eventListener is NOT active.")
      }
    };
    if (showMaterialDropdown) {
      document.addEventListener('mousedown', handleOutsideClick);
      // console.log("eventListener is active.")
    }    
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      // console.log("eventListener is NOT active.")
    };
  }, [showMaterialDropdown]);

  // Owner ----------------------------
  useEffect (() => {
    getOwners();
  }, [])
  let getOwners = async () => {
    try {
      let response = await api.get('/serviceFEx-api-v1/owners/')
      if (response.status === 200) {
        setOwners(response.data)
      }
    } catch (error) {
      console.error("Pregled ni shranjen.\n Error:\n", error.message);
    }
  }
  const handleChangeOwner = (event) => {
    // if 'e' is not in focus, just hide the dropdown and return
    if (!(event.target === document.activeElement)) {
      setShowOwnerDropdown(false)
      return
    }
    setFormOwner(event.target.value)
    setShowOwnerDropdown(true)
  }

  // Handle 'Submit' ------------------
  const handleSubmit = async (e) => {
    e.preventDefault();

    // toast.success ('This is success');
    // toast.error ('This is error');
    // toast.info ('This is info');
    // toast.warning ('This is warning');
    
    // console.log("Check submitted")
    if (formMaintenanceTypes.length < 1) {
      // alert("Vnesi vsaj eno vrsto pregleda.")
      toast.warning ('Vnesi vsaj eno vrsto pregleda.');
    }
    else if (errorDateValiditySmallerThanMaintenance) {
      toast.warning (`Pregled ni shranjen. Preveri podatke.\n${errorDateValiditySmallerThanMaintenance}`);
    }
    else {
      let dataToSend = {
        "serial_number": formSN,
        "production_year": formProductionYear,
        "manufacturer": formManufacturer,
        "type": formType,
        "owner": formOwner,
        "object": formObject,
        "location": formLocation,
        "fex_note": formFexNote,
        "maintenance_type": formMaintenanceTypes,
        "maintenance_date": formMaintenanceDate,
        "valid_date": formValidityDate,
        "used_material": formUsedMaterial,
        "serviceman": user.username,
        "check_note": formCheckNote,
        "check_id": checkID,
        }
      try {
        // const response = await fetch (`${process.env.REACT_APP_API_ENDPOINT}/serviceFEx-api-v1/checks/new/`, {
        //   method:'POST',
        //   headers:{
        //     'Content-Type': 'application/json'
        //   },
        //   body: JSON.stringify(dataToSend)
        // });
        const response = await api.post(`${process.env.REACT_APP_API_ENDPOINT}/serviceFEx-api-v1/checks/new/`, dataToSend);
        // console.log(response)
        // const responseData = await response.json();
        if (response.status === 200){          
          // console.log('Success:', responseData);
          toast.success ("Pregled shranjen.");
          window.scrollTo(0, 0)
          setFormSN("")
          setFormProductionYear(0)
          setFormManufacturer("")
          setFormType("")
          setFormMaintenanceTypes([])
          setFormOwner("")
          setFormObject("")
          setFormLocation("")
          setFormFexNote("")
          setFormMaintenanceDate(today.toISOString().slice(0, 10))
          setFormValidityDate(after_one_year.toISOString().slice(0, 10))
          setFormUsedMaterial([])
          setFormCheckNote("")
        }
        else {
          // console.error('Unsuccessful response:', response);
          // alert(`Pregled ni vnešen. Preveri podatke. ${responseData.error}`);
        }
      }
      catch (error) {
        // Handle any other errors that may occur during the fetch request
        // console.error('Error:', error);
        // alert('Prišlo je do napake. Kontaktiraj administratorja.');
        toast.error ('Pregled ni shranjen.\n' + 'Error:\n' + error + '\n' + error.response.data.message);
        console.log(error)
      }
      
    }
  };

  // const mystyle = 
  //   "[&>div>label]:block" +
  //   "[&>div>label]:mb-2" +
  //   "[&>div>label]:text-sm [&>div>label]:font-medium [&>div>label]:text-gray-900 [&>div>label>input]";
  // className={mystyle}

  return (
    <div className='ml-3 mr-3 mb-3 max-w-sm'>
      <h2 className="font-medium text-xl mb-2">Dodajanje pregleda ali prodaje RGA</h2>
      {!formSN && !formProductionYear && sn && year && fexsSnYear.length === 0 && (
        <p>
          Gasilnik s serijsko številko {sn} in letom proizvodnje {year} ne obstaja. <br />
          Spremeni URL ali podatke vnesi ročno.
        </p>
      )}
      <form
        onSubmit={handleSubmit}
        className="
        [&>div>label]:block
        [&>div>label]:mb-2
        [&>div>label]:text-sm
        [&>div>label]:font-medium
        [&>div>label]:text-gray-900
        
        [&>div>label>input]:bg-custom-form-bg
        [&>div>label>input]:border
        [&>div>label>input]:border-gray-300
        [&>div>label>input]:text-gray-900
        [&>div>label>input]:text-sm
        [&>div>label>input]:rounded-lg
        [&>div>label>input]:p-2.5
        [&>div>label>input]:block
        [&>div>label>input]:w-full
        [&>div>label>input:focus]:outline-custom-form-outline

        [&>div>label>select]:bg-custom-form-bg
        [&>div>label>select]:border
        [&>div>label>select]:border-gray-300
        [&>div>label>select]:text-gray-900
        [&>div>label>select]:text-sm
        [&>div>label>select]:rounded-lg
        [&>div>label>select]:p-2.5
        [&>div>label>select]:block
        [&>div>label>select]:w-full
        [&>div>label>select:focus]:outline-custom-form-outline
        "
      >
        <div data-input="SN" className="w-full">
          <label htmlFor="SN" className="!mb-0">*Serijska številka:
            <input
              required
              type="text"
              id="SN"
              value={formSN}
              // onChange={(e) => setFormSN(e.target.value)}
              onChange={handleChangeSn}
              onFocus={handleChangeSn}
              onBlur={handleChangeSn}
              ref={snInput}
            />
          </label>
          {/* {console.log("fexsSnYear.length === 0: ", fexsSnYear.length === 0)} */}
          {/* {console.log("!isInputSnFocused: ", !isInputSnFocused)}
          {console.log("(!isInputSnFocused && fexsSnYear.length === 0): ", (!isInputSnFocused && fexsSnYear.length === 0))} */}
          <div
            data-info="dropdown-container"
            className={`
              border
              border-gray-300
              rounded-lg
              w-96
              p-1
              cursor-pointer
              bg-custom-form-bg
              absolute
              ${showSnDropdown ? '' : 'hidden'}`}
            ref={snDropdownRef}
          >
            {fexsSnYear
              // .filter(fex => {
              //   const inputData = formSN.toLocaleLowerCase() + ' - ' + formProductionYear;
              //   const dbData = fex.fire_extinguisher.sn + ' - ' + fex.fire_extinguisher.production_year;
              //   // console.log(inputData)
              //   // console.log(dbData)
              //   // console.log(inputData === dbData)
              //   // return formSN && inputData !== dbData // && inputData.startsWith(dbData)
              //   return inputData !== dbData
              // })
              .map((fex, index) => (
              <div
                data-info="dropdown-line"
                className="hover:bg-custom-form-checked rounded bg-custom-form-bg_inside_bg m-1 whitespace-nowrap pl-2"
                key={index}
                onMouseDown={() => {
                  setFormSN(fex.sn);
                  setFormProductionYear(fex.production_year);
                  setFormManufacturer(fex.manufacturer.name);
                  setFormType(fex.type)
                  fex.owner && setFormOwner(fex.owner.name)
                  fex.object && setFormObject(fex.object)
                  fex.location && setFormLocation(fex.location)
                  fex.note && setFormFexNote(fex.note)
                  setFexsSnYear([]);
                  setShowSnDropdown(false)
                }}
              >
                {fex.sn} - {fex.production_year}
              </div>
            ))}
          </div>
        </div>

        <div data-input="production-year">
          <label htmlFor="production-year">*Leto proizvodnje:
            <input
              required
              type="number"
              id="production-year"
              value={(formProductionYear ? formProductionYear : "" )}
              onChange={(e) => {
                setFormProductionYear(e.target.value)
                // setFormSN("")
              }}
              min = {new Date().getFullYear()-40}
              max = {new Date().getFullYear()+1}
            />
          </label>
          <div
            data-info="years-container" 
            className={`
              flex
              flex-wrap
              ${formProductionYear ? 'hidden' : ''}
            `}
            
          >
            {new Date().getMonth() + 1 === 12 ? 
              <>
                <button type="button" onClick={() => {setFormProductionYear(new Date().getFullYear()+1)}} className="custom-button-sm">{new Date().getFullYear()+1}</button>
                <button type="button" onClick={() => {setFormProductionYear(new Date().getFullYear())}} className="custom-button-sm mx-2">{new Date().getFullYear()}</button>
                <button type="button" onClick={() => {setFormProductionYear(new Date().getFullYear()-1)}} className="custom-button-sm">{new Date().getFullYear()-1}</button>
              </>
              :
              <>
                <button type="button" onClick={() => {setFormProductionYear(new Date().getFullYear())}} className="custom-button-sm">{new Date().getFullYear()}</button>
                <button type="button" onClick={() => {setFormProductionYear(new Date().getFullYear()-1)}} className="custom-button-sm mx-2">{new Date().getFullYear()-1}</button>
                <button type="button" onClick={() => {setFormProductionYear(new Date().getFullYear()-2)}} className="custom-button-sm">{new Date().getFullYear()-2}</button>              
              </>
            }
          </div>
        </div>

        {/* ********************************************************************************************* */}
        {/* <div data-input="test-1">
          <input
            type="text"
            id="myInput"
            list="myList"
            pattern="Option 1|Option 2|Option 3"
            onInvalid={e => e.target.setCustomValidity('Pravilno vnesi ime proizvajalca')}
            onInput={e => e.target.setCustomValidity('')}
            />
          <datalist id="myList">
            <option value="Option 1" />
            <option value="Option 2" />
            <option value="Option 3" />
          </datalist>
        </div> */}
        {/* ********************************************************************************************* */}
        {/* <div data-input="test-2">
          <select id="myList">
            {manufacturers.map((manufacturer, index) => (
              <option key={`m-${index}`} value={`${manufacturer.name}`}>{`${manufacturer.name}`}</option>
            ))}
          </select>
        </div> */}
        {/* ********************************************************************************************* */}
        <div data-input="manufacturer">
          <label htmlFor="manufacturer">*Proizvajalec:
            <select
              onChange={(e) => setFormManufacturer(e.target.value)}
              required
              // size="4"
              id="manufacturer"
              value={formManufacturer}
              className="cursor-pointer p-6"
              

            >
              <option value='' className="overflow-y-scroll">-- Izberi proizvajalca --</option>
              {manufacturers.map((manufacturer, index) => (
                <option
                  key={`m-${index}`}
                  value={`${manufacturer.name}`}
                >
                  {`${manufacturer.name}`}
                </option>
              ))}
            </select>
          </label>
        </div>


        {/* ********************************************************************************************* */}


        {/* <div data-input="manufacturer">
          <label htmlFor="manufacturer" className="!mb-0">Proizvajalec:
            <input
              required
              type="text"
              id="manufacturer"
              value={formManufacturer}
              // onChange={(e) => setFormManufacturer(e.target.value)}
              onChange={handleChangeManufacturer}
              onFocus={handleChangeManufacturer}
              onBlur={handleChangeManufacturer}
            />
          </label>
          {
          // https://www.youtube.com/watch?v=Jd7s7egjt30
          }
          <div
            data-info="dropdown-container"
            className={`
              border
              border-gray-300
              rounded-lg
              w-96
              p-1
              cursor-pointer
              bg-custom-form-bg
              absolute
              ${showManufacturerDropdown ? '' : 'hidden'}
            `}
            ref={manufacturerDropdownRef}
          >
            {manufacturers.filter(manufacturer => {
              const inputData = formManufacturer.toLocaleLowerCase();
              const dbData = manufacturer.name.toLocaleLowerCase();
              return inputData === "" || (inputData && dbData.startsWith(inputData) && inputData !== dbData)
            }).slice(0, 3)
            .map((manufacturer, index) => (
              <div
                data-info="dropdown-line"
                key={index}
                className="hover:bg-custom-form-checked rounded bg-custom-form-bg_inside_bg m-1 whitespace-nowrap"
                onMouseDown={() => {
                  setFormManufacturer(manufacturer.name);
                  setShowManufacturerDropdown(false);
                }}
              >
                {manufacturer.name}
              </div>
            ))}
          </div>
        </div> */}

        <div data-input="type">
          <label htmlFor="type">*Tip:
            <input required type="text" id="type" value={formType} onChange={(e) => setFormType(e.target.value)} />
          </label>
        </div>

        <div data-input="maintenance-types" className="mb-2">
          <p>
            *Vrsta pregleda:
          </p>

          {maintenanceTypes.map((maintenanceType, index) => (
            <span key={index}>
              <label
                htmlFor={maintenanceType.type}
                className="
                  relative
                  top-[0.12rem]
                  ml-2
                  rounded
                  font-medium
                  text-red-500"
              >
                <input
                  // className="
                  //   border-gray-300
                  //   rounded
                  //   
                  //   "
                  className="
                    mr-2
                    w-4 h-4
                    bg-white
                    active:ring-custom-form-border
                    active:ring-2
                    rounded
                    checked:bg-gradient-to-tl
                    accent-custom-form-checked
                    "
                  id={maintenanceType.type}
                  type="checkbox"
                  value={maintenanceType.type}
                  checked={formMaintenanceTypes.includes(maintenanceType.type)}
                  onChange={handleChangeFormMaintenanceTypes}
                />
              </label>
              {maintenanceType.type}
              <br />
            </span>
          ))
          }
          
          {/* <p>Maintenance types: {formMaintenanceTypes.join(', ')}</p> */}
          {/* <label htmlFor="porabaMateriala">Poraba materiala:
            <select id="porabaMateriala" multiple onChange={handlePorabaMaterialaChange}>
              <option value="tesniloGlave">Tesnilo glave</option>
              <option value="prah">Prah</option>
              <option value="magnet">Magnet</option>
            </select>
          </label> */}
        </div>
        <div data-input="owner">
          <label htmlFor="owner" className="!mb-0">Lastnik:
            <input
              type="text"
              id="owner"
              value={formOwner}
              onChange={handleChangeOwner}
              onFocus={handleChangeOwner}
              onBlur={handleChangeOwner}
            />
          </label>
          <div
            data-info="dropdown-container"
            className={`
              border
              border-gray-300
              rounded-lg
              w-96
              p-1
              cursor-pointer
              bg-custom-form-bg
              absolute
              ${showOwnerDropdown && 
                owners.filter (owner => {
                  const inputData = formOwner.toLocaleLowerCase()
                  const dbData = owner.toLocaleLowerCase();
                  return inputData && dbData.startsWith(inputData) && inputData !== dbData // && inputData.startsWith(dbData)
                }).length > 0 ? '' : 'hidden'}
            `}
          >
            {owners
              .filter (owner => {
                const inputData = formOwner.toLocaleLowerCase()
                const dbData = owner.toLocaleLowerCase();
                return inputData && dbData.startsWith(inputData) && inputData !== dbData // && inputData.startsWith(dbData)
              })
              .map((owner, index) => (
              <div
                data-info="dropdown-line"
                className="hover:bg-custom-form-checked rounded bg-custom-form-bg_inside_bg m-1 whitespace-nowrap pl-2"
                key={index}
                onMouseDown={() => {
                  setFormOwner(owner)
                  setShowOwnerDropdown(false)
                }}
              >
                {owner}
              </div>
            ))}
          </div>
        </div>

        <div data-input="object">
        <label htmlFor="object">Objekt:
            <input
              type="text"
              id="object"
              value={formObject}
              onChange={(e) => {
                setFormObject(e.target.value)
              }}
            />
          </label>
        </div>

        <div data-input="location">
          <label htmlFor="location">Lokacija:
            <input
              type="text"
              id="location"
              value={formLocation}
              onChange={(e) => {
                setFormLocation(e.target.value)
              }}
            />
          </label>
        </div>

        <div data-input="fex-note">
          <label htmlFor="fex-note">Opomba gasilnika:
            <input
              type="text"
              id="fex-note"
              value={formFexNote}
              onChange={(e) => {
                setFormFexNote(e.target.value)
              }}
            />
          </label>
        </div>

        <div data-input="maintenance-date">
          <label htmlFor="maintenance-date">Datum vzdrževanja:
            <input
              type="date"
              id="maintenance-date"
              value={formMaintenanceDate}
              onChange={(e) => {
                setFormMaintenanceDate(e.target.value);
                  const calculateValidityDate = (maintenanceDate) => {
                    const maintenanceDateObj = new Date(maintenanceDate);
                    if (!isNaN(maintenanceDateObj)){
                      maintenanceDateObj.setFullYear(maintenanceDateObj.getFullYear() + 1);
                      return maintenanceDateObj.toISOString().slice(0, 10);
                    }
                    // const validityDateObj = new Date(maintenanceDateObj.getFullYear() + 1, maintenanceDateObj.getMonth(), maintenanceDateObj.getDate());
                    
                  };
                setFormValidityDate(calculateValidityDate(e.target.value));
                setErrorDateValiditySmallerThanMaintenance(null)
              }}
              max = {new Date().toISOString().split('T')[0]}
            />
          </label>
        </div>

        <div data-input="validity-date">
          <label htmlFor="validity-date">Veljavno do:
          {/* TODO get validity date from backend. If current validity date expires before one month, set 'validity-date' after one year from this value */}
            <input
              required
              type="date"
              id="validity-date"
              value={formValidityDate}
              onChange={(e) => {
                const validityDate = e.target.value
                if (formMaintenanceDate > validityDate) {
                  setErrorDateValiditySmallerThanMaintenance('Datum veljavnosti mora biti večji od datuma vzdrževanja.')
                } else {
                  setFormValidityDate(validityDate);
                  setErrorDateValiditySmallerThanMaintenance(null)
                }
              }}
            />
          </label>
          {errorDateValiditySmallerThanMaintenance &&
            <p className={styleErrorMessage}>{errorDateValiditySmallerThanMaintenance}</p>
          }
        </div>

        <div
          data-input="used-material"
          className="relative"
        >
          <p 
            className="
              block
              mb-2
              text-sm
              font-medium
              text-gray-900"
          >Porabljen material:</p>
          {
            formUsedMaterial.map( (mat,indexUsedMaterial) =>
              <div
                data-info="one-used-material-container"
                key={indexUsedMaterial}
                className="
                  border
                  rounded-lg
                  border-gray-300
                  px-2
                  pb-2
                  w-96
                  mb-2
                  bg-custom-form-bg"
              >
                <label className="
                  block
                  mb-0
                  text-xs
                  font-medium
                  text-gray-900
                  "
                >
                  Material
                  <input
                  type="text"
                  name="material"
                  value={mat.material}
                  onChange={(e)=>handleChangeMaterial(e, indexUsedMaterial)}
                  onFocus={(e)=>handleChangeMaterial(e, indexUsedMaterial)}
                  onBlur={(e) => handleChangeMaterial(e, indexUsedMaterial)}
                  className="
                    bg-custom-form-bg_inside_bg
                    border
                    border-gray-300
                    text-gray-900
                    text-sm
                    rounded-lg
                    p-2.5
                    block
                    w-full
                    outline-custom-form-outline"
                  ref={materialDropdownRef}
                  />
                </label>
                {/* {console.log("****************************")}
                {console.log("fexSnYear length: ", fexsSnYear.length)}
                {console.log("materials length: ", materials.length)}
                {console.log(", formUsedMaterial[0]['material'] length: ", formUsedMaterial[0]['material'].length)}
                {console.log("formUsedMaterial: ", formUsedMaterial)} */}
                <div
                  data-info="dropdown-container"
                  className={`
                    border
                    border-gray-300
                    rounded-lg
                    w-[23rem]
                    p-1
                    cursor-pointer
                    bg-custom-form-bg_inside_bg
                    absolute
                    ${showMaterialDropdown === indexUsedMaterial && 
                      materials.filter (material => {
                        const inputData = formUsedMaterial[indexUsedMaterial]['material'].toLocaleLowerCase()
                        const dbData = material.name.toLocaleLowerCase();
                        return inputData && dbData.startsWith(inputData) && inputData !== dbData // && inputData.startsWith(dbData)
                      }).length > 0 ? '' : 'hidden'}
                  `}
                > 
                  {/* console.log("materials: ", materials) */}
                  {/* {console.log("materials: ", materials)} */}
                  {materials
                    .filter (material => {
                      const inputData = formUsedMaterial[indexUsedMaterial]['material'].toLocaleLowerCase()
                      const dbData = material.name.toLocaleLowerCase();
                      return inputData && dbData.startsWith(inputData) && inputData !== dbData // && inputData.startsWith(dbData)
                    })
                    .map((material, indexDropDown) => (
                      <div
                        data-info="dropdown-line"
                        className="hover:bg-custom-form-checked rounded bg-custom-form-bg_inside_bg m-1 whitespace-nowrap"
                        key={indexDropDown}
                        //onClick={() => {
                        onMouseDown={() => {
                          // console.log("inside 'onClick'")
                          const onChangeVal = [...formUsedMaterial]
                          onChangeVal[indexUsedMaterial]['material']=material.name
                          onChangeVal[indexUsedMaterial]['unit']=material.unit
                          setFormUsedMaterial(onChangeVal)
                          setShowMaterialDropdown(null)
                          if (inputMaterialUnitRef.current) {
                            inputMaterialUnitRef.current.disabled = true;
                          }
                          setTimeout(() => {
                            if (inputMaterialQuantityRef.current) {
                              inputMaterialQuantityRef.current.focus();
                            }
                          }, 10)
                        }}
                        ref={materialDropdownLineRef}
                      >
                        {`${material.name} [${material.unit}]` }
                      </div>
                    ))
                  }
                  {}
                </div>
                <label className="
                  block
                  mb-2
                  text-xs
                  font-medium
                  text-gray-900"
                >
                  Enota [kg, l, kos...]
                  <input
                    required={mat.material ? "required" : ""}
                    type="text"
                    name="unit"
                    value={mat.unit}
                    onChange={(e)=>handleChangeMaterial(e, indexUsedMaterial)}
                    disabled={mat.material ? "" : "disabled"}
                    className="
                      bg-custom-form-bg_inside_bg
                      border
                      border-gray-300
                      text-gray-900
                      text-sm
                      rounded-lg
                      p-2.5
                      block
                      w-full
                      outline-custom-form-outline"
                    ref={inputMaterialUnitRef}
                  />
                </label>
                <label className="
                  block
                  mb-2
                  text-xs
                  font-medium
                  text-gray-900"
                >
                  Količina
                  <input 
                    required={mat.material ? "required" : ""}
                    type='number'
                    min="1"
                    name="quantity"
                    value={mat.quantity}
                    onChange={(e)=>handleChangeMaterial(e, indexUsedMaterial)}
                    disabled={mat.material ? "" : "disabled"}
                    // {true ? "" : ""}
                    className="
                      bg-custom-form-bg_inside_bg
                      border
                      border-gray-300
                      text-gray-900
                      text-sm
                      rounded-lg
                      p-2.5
                      block
                      w-full
                      outline-custom-form-outline"
                    ref={inputMaterialQuantityRef}
                  />
                </label>
                <button type="button" onClick={()=>handleDeleteMaterial(indexUsedMaterial)} className="custom-button-sm">Odstrani</button>
              </div>
            )
          }
          <button type="button" onClick={handleAddMaterial} className="custom-button-sm">Dodaj material</button>
        </div>

        <div data-input="check-note">
          <label htmlFor="check-note" className='mt-2'>Opomba pregleda:
            <input
              type="text"
              id="check-note"
              value={formCheckNote}
              onChange={(e) => {
                setFormCheckNote(e.target.value)
              }}
            />
          </label>
        </div>
        <button type="submit" className="custom-button">{!checkID ? "Shrani pregled" : "Uredi pregled"}</button>
      </form>
    </div>
  )
}

export default Check