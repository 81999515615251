import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import TableOfChecks from './components/TableOfChecks'
import useAxios from './utils/useAxios'

const ProductionYear = () => {
  const production_year = useParams().year
  let [checks, setChecks] = useState([])
  const [url, setUrl] = useState();


  let api = useAxios()


  useEffect (() => {
    if (production_year) {
      // getFExProductionYear()
      setUrl("/serviceFEx-api-v1/checks?year=" + production_year)
    }
  }, [production_year])
  // let getFExProductionYear = async () => {
  //   try {
  //     let response = await api.get(`/serviceFEx-api-v1/checks?year=${production_year}`)
  //     // let data = await response.json()
  //     if (response.status === 200) {
  //       setChecks(response.data)
  //     }
  //   } catch (error) {
  //     console.error("An error occurred:", error.message);
  //   }
  // }

  return (
    <div>
      Seznam pregledov za gasilnike, proizvedene leta {production_year}:
      <TableOfChecks url={url} />
    </div>
  )
}

export default ProductionYear
