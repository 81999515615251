import React, { useState, useRef, useEffect } from 'react'


const Test = () => {

  const [searchTerm, setSearchTerm] = useState('')
  const [showDropdown, setShowDropdown] = useState(false); // State to control the visibility of the dropdown
  const dropdownRef = useRef(null);
  const snInput = useRef(null);

  var data = [
    { "name": "Alice" },
    { "name": "Bob" },
    { "name": "Boby" },
    { "name": "Brain"} ,
    { "name": "Brian"},
    { "name": "Barlie"},
    { "name": "Dave" },
    { "name": "Eve" },
    { "name": "Charlie" },
    { "name": "David" },
    { "name": "Ella" },
    { "name": "Frank" },
    { "name": "Grace" },
    { "name": "Henry" },
    { "name": "Isabel" },
    { "name": "Jack" },
    { "name": "Kate" },
    { "name": "Liam" },
    { "name": "Mia" },
    { "name": "Noah" },
    { "name": "Olivia" },
    { "name": "Peter" },
    { "name": "Quinn" },
    { "name": "Ryan" },
    { "name": "Sophia" }
  ];

  const onChange = (event) => {
    setSearchTerm(event.target.value);
    setShowDropdown(true); // Show the dropdown when input changes
  }

  const onSearch = (searchTerm) => {
    console.log("search term: ", searchTerm);
    setSearchTerm(searchTerm)
    setShowDropdown(false); // Hide the dropdown when a search is triggered
  }

  useEffect(() => {
    // Add event listener to close dropdown when clicking outside
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
        document.removeEventListener('mousedown', handleOutsideClick);
      console.log("eventListener is NOT active.")
      }
    };

    if (showDropdown) {
      document.addEventListener('mousedown', handleOutsideClick);
      console.log("eventListener is active.")
    }
    
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      console.log("eventListener is NOT active.")
    };
  }, [showDropdown]);

  useEffect(() => { // focus on input field when page is loaded
    if (snInput.current) {
      snInput.current.focus();
    }
  }, []);

  return (
    <div>
      <h1>Search</h1>
      <div>
        <div>
          <input
            data-info="input_field"
            type='text'
            value={searchTerm}
            onChange={onChange}
            // onBlur={() => setShowDropdown(false)}
            ref={snInput}
          />
          <br />
          <button data-info="search_button" type='button' onClick={() => onSearch(searchTerm)}>Search</button>
        </div>
        <div
          data-info="dropdown-container"
          ref={dropdownRef}
          className={`border w-fit ${showDropdown ? 'visible' : 'hidden'}`}
        >
          {data
          .filter(item => {
            return searchTerm && item.name.toLocaleLowerCase().startsWith(searchTerm.toLocaleLowerCase()) // && item.name.toLocaleLowerCase() !== searchTerm.toLowerCase()
          })
          .slice(0, 3)
          .map((item, index) => (
            <div
              data-info="dropdown-row"
              key={index}
              onClick={()=>onSearch (item.name)}
              className="border"
            >
              {item.name}
            </div>
          )
          
          )}
        </div>
      </div>
      {console.log("value: ", searchTerm)}
    </div>
  )
}



export default Test
